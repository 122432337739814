export enum MUTATIONS {
  ADD_TICKET = 'ADD_TICKET',
  REMOVE_TICKET = 'REMOVE_TICKET',
  REMOVE_TICKET_GROUP = 'REMOVE_TICKET_GROUP',
  SET_AVAILABLE_DATES = 'SET_AVAILABLE_DATES',
  SET_TIME = 'SET_TIME',
  SET_ORDER_ID = 'SET_ORDER_ID',
  SET_ERROR = 'SET_ERROR',
  SET_NAW = 'SET_NAW',
  SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN',
  IS_FIRST_LOGIN = 'IS_FIRST_LOGIN',
  SET_IS_ADMIN = 'SET_IS_ADMIN',
  SET_AUTH_TOKEN = 'SET_AUTH_TOKEN',
  RESET_TICKETS = 'RESET_TICKETS',
  ADD_URL_ACTION = 'ADD_URL_ACTION',
  ADD_VOUCHER = 'ADD_VOUCHER',
  SET_ARTICLES = 'SET_ARTICLES',
  SET_URL_ACTION_TICKETS = 'SET_URL_ACTION_TICKETS',
}
