import { ACTIONS } from '@/constants/actionNames';
import { GETTERS } from '@/constants/getterNames';
import { BUSINESS_ROUTES } from '@/constants/routeNames';
import { IDelegate } from '@/interfaces/IDelegate';
import ITicket from '@/interfaces/ITicket';
import ROUTER from '@/router';
import store from '@/store';
import { i18n } from '@/i18n';

interface ICustomDateResponse extends Date {
  timestamp: number;
}

export class BusinessDelegate implements IDelegate {

  public async beforeRouteEnterPayment(): Promise<boolean> {
    const hasTickets = store.getters[GETTERS.GET_SELECTED_TICKETS].length > 0;
    const hasSelectedTime = typeof store.getters[GETTERS.GET_TIME_SLOT].hour !== 'undefined';
    const isLoggedIn = store.getters[GETTERS.GET_IS_LOGGED_IN];

    if (!isLoggedIn) {
      await ROUTER.push({
        name: BUSINESS_ROUTES.ROUTE_LOGIN
      });

      return false;
    }

    if (!hasTickets && !hasSelectedTime) {
      await ROUTER.push({
        name: BUSINESS_ROUTES.ROUTE_TICKETS
      });

      return false;
    } else if (hasTickets && !hasSelectedTime) {
      await ROUTER.push({
        name: BUSINESS_ROUTES.ROUTE_DATE
      });

      return false;
    }

    return true;
  }

  public async beforeRouteEnterTickets(): Promise<boolean> {
    const isLoggedIn = store.getters[GETTERS.GET_IS_LOGGED_IN];
    const isFirstLogin = store.getters[GETTERS.GET_IS_FIRST_LOGIN];

    if (!isLoggedIn || isFirstLogin) {
      await ROUTER.push({
        name: BUSINESS_ROUTES.ROUTE_LOGIN
      });

      return false;
    }

    return true;
  }

  public async beforeRouteEnterNaw(): Promise<any> {

  }

  public async beforeRouteEnterDate(): Promise<boolean> {
    const hasTickets = store.getters[GETTERS.GET_SELECTED_TICKETS].length > 0;
    const isLoggedIn = store.getters[GETTERS.GET_IS_LOGGED_IN];
    const hasSelectedTime = typeof store.getters[GETTERS.GET_TIME_SLOT].hour !== 'undefined';

    if (hasSelectedTime) {
      await ROUTER.push({
        name: BUSINESS_ROUTES.ROUTE_COMPLETE
      });
    }

    if (!isLoggedIn) {
      await ROUTER.push({
        name: BUSINESS_ROUTES.ROUTE_LOGIN
      });
    }

    if (!hasTickets) {
      await ROUTER.push({
        name: BUSINESS_ROUTES.ROUTE_TICKETS
      });

      return false;
    }

    return true;
  }

  // Start ticket functions
  public fetchTickets() {
    void store.dispatch(ACTIONS.FETCH_TICKETS, i18n.locale);
  }

  public fetchArticles() {
    void store.dispatch(ACTIONS.FETCH_ARTICLES, i18n.locale);
  }

  public tickets(): ITicket[] {
    return store.getters[GETTERS.GET_TICKETS];
  }

  public articles(): ITicket[] {
    return store.getters[GETTERS.GET_ARTICLES];
  }

  public nextTicket(): void {
    void ROUTER.push({
      name: BUSINESS_ROUTES.ROUTE_DATE,
    });
  }

  // Start date functions
  public nextDate(): void {
    void ROUTER.push({
      name: BUSINESS_ROUTES.ROUTE_ARTICLES,
    });
  }

  public previousDate(): void {
    void ROUTER.push({
      name: BUSINESS_ROUTES.ROUTE_TICKETS,
    });
  }

  public nextArticles(): void {
    void ROUTER.push({
      name: BUSINESS_ROUTES.ROUTE_COMPLETE,
    });
  }

  public previousArticles(): void {
    void ROUTER.push({
      name: BUSINESS_ROUTES.ROUTE_DATE,
    });
  }

  // Start naw functions
  public nextNaw(): void {
    // no need to implement
  }

  public previousNaw(): void {
    // no need to implement
  }

  public changedMonth(date: ICustomDateResponse): void {
    if (typeof date.timestamp !== 'undefined') {
      void store.dispatch(ACTIONS.FETCH_TIMES, {
        time: new Date(date.timestamp),
        locationId: 1
      });

      return;
    }

    void store.dispatch(ACTIONS.FETCH_TIMES, {
      time: date,
      locationId: 1
    });
  }

  public loadDates(): void {
    void store.dispatch(ACTIONS.FETCH_TIMES, {
      time: new Date(),
      locationId: 1
    });
  }
}
