import { ACTIONS } from '@/constants/actionNames';
import { GETTERS } from '@/constants/getterNames';
import { TICKET_ROUTES } from '@/constants/routeNames';
import { IDelegate } from '@/interfaces/IDelegate';
import ITicket from '@/interfaces/ITicket';
import ROUTER from '@/router';
import store from '@/store';
import selectedTickets from '@/interfaces/ISelectedTickets';
import { i18n } from '@/i18n';
import moment from 'moment';

interface ICustomDateResponse extends Date {
  timestamp: number;
}

export class TicketDelegate implements IDelegate {

  public async beforeRouteEnterPayment(): Promise<any> {
    const hasOrder = store.getters[GETTERS.GET_ORDER_ID] !== 0;

    if (!hasOrder) {
      ROUTER.push({
        name: TICKET_ROUTES.ROUTE_NAW
      });

      return false;
    }

    return true;
  }

  public async beforeRouteEnterTickets(): Promise<any> {
    const hasOrder = store.getters[GETTERS.GET_ORDER_ID] !== 0;

    if (hasOrder) {
      ROUTER.push({
        name: TICKET_ROUTES.ROUTE_PAYMENT
      });

      return false;
    }

    return true;
  }

  public async beforeRouteEnterNaw(): Promise<boolean> {
    const hasTickets = store.getters[GETTERS.GET_SELECTED_TICKETS].length > 0;
    const hasSelectedTime = typeof store.getters[GETTERS.GET_TIME_SLOT].hour !== 'undefined';
    const hasOrder = store.getters[GETTERS.GET_ORDER_ID] !== 0;

    if (hasOrder) {
      ROUTER.push({
        name: TICKET_ROUTES.ROUTE_PAYMENT
      });

      return false;
    }

    if (!hasTickets && !hasSelectedTime) {
      ROUTER.push({
        name: TICKET_ROUTES.ROUTE_TICKETS
      });

      return false;
    } else if (hasTickets && !hasSelectedTime) {
      ROUTER.push({
        name: TICKET_ROUTES.ROUTE_DATE
      });

      return false;
    }

    return true;
  }

  public async beforeRouteEnterDate(): Promise<boolean> {
    const hasTickets = store.getters[GETTERS.GET_SELECTED_TICKETS].length > 0;
    const hasOrder = store.getters[GETTERS.GET_ORDER_ID] !== 0;

    if (hasOrder) {
      ROUTER.push({
        name: TICKET_ROUTES.ROUTE_PAYMENT
      });

      return false;
    }

    if (!hasTickets) {
      ROUTER.push({
        name: TICKET_ROUTES.ROUTE_TICKETS
      });

      return false;
    }

    return true;
  }

  // Start Ticket Component functions
  public fetchTickets(): void {
    store.dispatch(ACTIONS.FETCH_TICKETS, i18n.locale);
  }

  public fetchArticles(): void {
    store.dispatch(ACTIONS.FETCH_ARTICLES, i18n.locale);
  }

  public nextTicket(): void {
    ROUTER.push({
      name: TICKET_ROUTES.ROUTE_DATE,
    });
  }

  public tickets(): ITicket[] {
    return store.getters[GETTERS.GET_TICKETS];
  }

  public articles(): ITicket[] {
    return store.getters[GETTERS.GET_ARTICLES];
  }

  // Start Date Component functions
  public nextDate(): void {
    ROUTER.push({
      name: TICKET_ROUTES.ROUTE_ARTICLES,
    });
  }

  public previousDate(): void {
    ROUTER.push({
      name: TICKET_ROUTES.ROUTE_TICKETS,
    });
  }

  public nextArticles(): void {
    ROUTER.push({
      name: TICKET_ROUTES.ROUTE_NAW,
    });
  }

  public previousArticles(): void {
    ROUTER.push({
      name: TICKET_ROUTES.ROUTE_DATE,
    });
  }

  // Start NAW Component functions
  public previousNaw(): void {
  }

  public nextNaw(): void {
    ROUTER.push({
      name: TICKET_ROUTES.ROUTE_PAYMENT,
    });
  }

  public changedMonth(date: ICustomDateResponse): void {
    const tickets: selectedTickets[] = store.getters[GETTERS.GET_SELECTED_TICKETS];
    const currentDates = store.getters[GETTERS.GET_AVAILABLE_DATES];
    const maxDate = currentDates.reduce((max: { date: moment.MomentInput; }, curr: { date: moment.MomentInput; }) => {
      return moment(max.date).isAfter(curr.date) ? max : curr;
    });
    const currentDate =  moment(date.timestamp ? date.timestamp : date);

    if (currentDate.isAfter(moment(maxDate.date))) {
      store.dispatch(ACTIONS.FETCH_TIMES, {
        time: currentDate.toDate(),
        locationId: tickets[0].item.LocationId
      });
    }
  }

  public loadDates(): void {
    const tickets: selectedTickets[] = store.getters[GETTERS.GET_SELECTED_TICKETS];

    store.dispatch(ACTIONS.FETCH_TIMES, {
      time: new Date(),
      locationId: tickets[0].item.LocationId
    });
  }
}
