
import { MUTATIONS } from '@/constants/mutationNames';
import ITicket from '@/interfaces/ITicket';
import FORMAT_CURRENCY from '@/utils/currencyFormatterUtil';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Tickets extends Vue {

  @Prop({type: Array, default: []})
  public readonly tickets!: ITicket[];

  @Prop({type: Boolean, default: false})
  public readonly isVoucher!: boolean;

  public data() {
    return {
      isShown: false,
    };
  }

  public formatCurrency = (price: number): string => FORMAT_CURRENCY(price);

  public addTicket(ticket: ITicket) {
    this.$store.commit(MUTATIONS.ADD_TICKET, ticket);
  }
}
