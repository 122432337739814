import IError from '@/interfaces/IError';
import INaw from '@/interfaces/INaw';
import { IState } from '@/interfaces/IState';
import ITime from '@/interfaces/ITime';

export default {
  orderId: 0,
  availableDates: [],
  giftcards: [],
  workshops: [],
  nocturnes: [],
  time: {} as ITime,
  error: {} as IError,
  sellTickets: [],
  selectedTickets: [],
  articles: [],
  naw: {} as INaw,
  isAdmin: false,
  firstLogin: false,
  isLoggedIn: false,
  authToken: '',
  urlActionCodes: [],
  urlActionTickets: [],
  voucherCodes: [],
} as IState;
