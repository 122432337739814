
import { Component, Vue } from 'vue-property-decorator';
import FlagComponent from '@/components/General/FlagComponent.vue';
import translation from '@/translation';

@Component({
  components: {
    FlagComponent,
  }
})
export default class App extends Vue {
  get languages() {
    return Object.keys(translation);
  }

  get currentYear() {
    const d = new Date();
    return d.getFullYear();
  }
}
