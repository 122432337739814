
import Loading from '@/components/General/Loading.vue';
import { GETTERS } from '@/constants/getterNames';
import ISelectedTickets from '@/interfaces/ISelectedTickets';
import ITicket from '@/interfaces/ITicket';
import tickets from '@/components/Ticket/Tickets.vue';
import DelegateLoader from '@/mixins/DelegateLoader';
import { Component, Mixins } from 'vue-property-decorator';
import VoucherMixin from '@/mixins/VoucherMixin';
import { MUTATIONS } from '@/constants/mutationNames';

@Component({
  components: {
    Loading,
    tickets,
  },
})
export default class TicketComponent extends Mixins(DelegateLoader, VoucherMixin) {
  public voucherInput: string = '';

  get selectedTickets(): ISelectedTickets[] {
    return this.$store.getters[GETTERS.GET_SELECTED_TICKETS];
  }

  get urlActionTickets() {
    return this.$store.getters[GETTERS.GET_URL_ACTION_TICKETS];
  }

  get tickets(): ITicket[] {
    return this.delegate.tickets();
  }

  public async mounted() {
    await this.delegate.beforeRouteEnterTickets();

    this.$store.commit(MUTATIONS.SET_AVAILABLE_DATES, {});
  }

  public created() {
    this.delegate.fetchTickets();
  }

  public nextStep(): void {
    this.delegate.nextTicket();
  }

}
