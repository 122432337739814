
import { Component, Prop, Vue, } from 'vue-property-decorator';
import { MUTATIONS } from '@/constants/mutationNames';
import { EventBus, EventBusTypes } from '@/Eventbus';

@Component
export default class FlagComponent extends Vue {
  @Prop({type: String, default: ''})
  public language!: string;

  @Prop({type: String, default: ''})
  public activeStyle!: string;

  @Prop({type: String, default: ''})
  public inactiveStyle!: string;

  changeLanguage() {
    EventBus.$emit(EventBusTypes.TRANSLATION_UPDATE, {});
    this.$store.commit(MUTATIONS.SET_AVAILABLE_DATES, []);
    this.$store.commit(MUTATIONS.SET_NAW, {});
    this.$store.commit(MUTATIONS.SET_ORDER_ID, 0);
    this.$store.commit(MUTATIONS.SET_TIME, {});
    this.$store.commit(MUTATIONS.RESET_TICKETS, []);
    this.$store.commit(MUTATIONS.SET_URL_ACTION_TICKETS, []);

    this.$router.push({
      path: `/${this.language}`,
    });

    this.$i18n.locale = this.language;
  }
}
