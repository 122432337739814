
import Header from '@/components/General/Header.vue';
import { MUTATIONS } from '@/constants/mutationNames';
import { Component, Vue } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import { FLOW_BUSINESS } from '@/router';
import { GETTERS } from '@/constants/getterNames';

@Component({
  components: {
    Header,
  },
})
export default class BusinessesView extends Vue {

  public routes: RouteConfig[] = FLOW_BUSINESS;

  get error() {
    return this.$store.getters[GETTERS.GET_ERROR];
  }

  public created() {
    this.$store.commit(MUTATIONS.RESET_TICKETS);
  }

  public dismissError() {
    this.$store.commit(MUTATIONS.SET_ERROR, {});
  }
}
