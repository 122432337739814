import Vue from 'vue';
import VueI18n from 'vue-i18n';
import translation from '@/translation';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'nl',
  messages: translation as any,
});
