import ITranslation from '@/interfaces/ITranslation';

const german: ITranslation = {
  buttons: {
    next: 'Fortfahren',
    previous: 'Zurück'
  },
  articlePage: {
    title: 'Wähle deine Artikel aus',
    paragraph: '<b>Optional: <br/> Wähle aus den folgenden hausgemachten Schokoladen-Köstlichkeiten zu einem ermäßigten Preis.</b>',
    next: 'OHNE ERMÄSSIGUNG WEITER'
  },
  dateTimePage: {
    legend: {
      availableDate: 'Verfügbar',
      chosenDate: 'Ausgewähltes Datum',
      notAvailableDate: 'Nicht verfügbar',
      title: 'Legende'
    },
    title: 'Wähle ein Besuchsdatum aus',
    workShop: {
      placesAvailable: 'Noch {0} Plätze frei',
      placeAvailable: 'Noch {0} Platz frei'
    },
    times: {
      title: 'Wähle deine Startzeit',
      choose: 'Wähle'
    }
  },
  errors: {
    noTimesAvailable: 'Es wurden keine Zeitfenster gefunden',
    createOrder: 'Bei der Erstellung Ihrer Bestellung ist ein Fehler aufgetreten.',
    loginDenied: 'Füllen Sie alle Informationen aus',
    repeatPassword: 'Die gewählten Passwörter stimmen nicht überein',
    updateData: 'Beim Aktualisieren Ihrer Informationen ist ein Problem aufgetreten.',
    emailNotAdded: 'Beim Hinzufügen dieser E-Mail-Adresse ist ein Problem aufgetreten.',
    emailNotRemoved: 'Beim Löschen dieser E-Mail-Adresse ist ein Problem aufgetreten.',
    gettingUsers: 'Beim Abrufen aller Benutzer ist ein Problem aufgetreten.',
    whoops: 'Hoppla!',
    naw: {
      error: 'Sie haben vergessen, einige Felder auszufüllen. Bitte überprüfen Sie alle Felder erneut, bevor Sie fortfahren.',
      accept: 'Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen!',
      answer: 'Bitte beantworten Sie die oben gestellte Frage!',
      email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein!',
      firstName: 'Bitte geben Sie einen Vornamen ein!',
      lastName: 'Bitte geben Sie einen Nachnamen ein!',
      acceptCorona: 'Bitte akzeptieren Sie unsere Hygienemaßnahmen!',
      country: 'Bitte geben Sie Ihr Herkunftsland ein!'
    }
  },
  footer: {
    copyright: 'The Belgian Chocolate Experience'
  },
  header: {
    dateTime: 'Datum/Zeit',
    payment: 'Zahlung',
    personalDetails: 'Deine Daten',
    selectItems: 'Gegenstände auswählen',
    round: 'Vollständig',
    selectTickets: 'Eintrittskarten auswählen',
    login: 'Anmeldung',
    articles: 'Chocolade-Ermäßigung'
  },
  loaders: {
    collectingAvailableTimes: 'Verfügbare Zeiten werden geladen',
    creatingPayment: 'Innerhalb weniger Sekunden werden Sie auf die Zahlungsseite weitergeleitet.',
    collectingTickets: 'Ihre Tickets werden abgerufen',
    checkingPersonalDetails: 'Ihre Informationen werden verarbeitet.',
    loadingHeader: 'Einen Augenblick bitte...',
    loadingAdmin: 'Korrekte Informationen werden geladen',
    finishingOrder: 'Ihre Bestellung wird bearbeitet'
  },
  normalTicketPage: {
    minimumTicketSelection: 'Wähle mindestens 1 Ticket aus, um fortzufahren. Für Kinder unter 4 Jahren ist keine (Zeitfenster-)Reservierung erforderlich. Sie dürfen mit ihrer Begleitperson hinein.\n',
    title: 'Wähle deine Tickets aus'
  },
  paymentPage: {
    paymentOverview: {
      button: 'zur Zahlung',
      notice: 'Wenn die Zahlung erfolgreich ist, erhalten Sie Ihre E-Tickets im Posteingang der angegebenen E-Mail-Adresse. Das kann mehrere Minuten dauern.',
      title: 'Bestellübersicht'
    },
    title: 'Bestellung abschließen'
  },
  personalDetailsPage: {
    marketing: 'Ja, ich bin ein Schokoladenfan und möchte die neuesten Schokolade-Nachrichten erhalten',
    coronaCheckBox: 'Bei der Reservierung werden die Besucher gebeten, zusätzlich zu den Besuchsbedingungen des Museums den Hygienemaßnahmen und den Maßnahmen bei gesundheitlichen Beeinträchtigungen zuzustimmen.*',
    checkBox: 'Mit dieser Bestellung erkläre ich mich mit den <a href=\'https://www.chocolatenation.be/de/buchungsbedingungen-tickets\' target=\'_blank\'>Allgemeinen Geschäftsbedingungen einverstanden</a>*',
    email: 'E-Mail-Addresse',
    emailRepeat: 'Wiederholung der E-Mail-Adresse',
    firstName: 'Vorname',
    lastName: 'Nachname',
    middleName: 'Zweiter Vorname',
    notice: {
      description: 'E-Tickets werden an die hier angegebene E-Mail-Adresse gesendet. Überprüfe deshalb, ob du diese richtig eingegeben hast.',
      title: 'Wichtige Mitteilung!',
    },
    phoneNumber: 'Telefonnummer',
    birthDay: {
      labelActive: 'Geben Sie Ihr Geburtsdatum ein, um an Ihrem Geburtstag etwas Lustiges zu erhalten',
      labelInactive: 'Geburtsdatum - Abonnieren Sie unseren Newsletter, um an Ihrem Geburtstag etwas Lustiges zu erhalten',
      notice: 'Ja, ich bin ein Schokoladenfan und möchte die neuesten Nachrichten über Schokolade erhalten'
    },
    title: 'Persönliche Angaben',
    country: 'Land',
  },
  selectionPage: {
    nocturnesTicket: {
      description: 'Bestellen Sie hier Tickets für Nocturne',
      title: 'Nocturne-Tickets'
    },
    giftCardTicket: {
      description: 'Hier kannst du Geschenkgutscheine bestellen.',
      title: 'Gutscheine'
    },
    normalTicket: {
      description: 'Mit diesen Tickets erhältst du Zugang zu Chocolate Nation.',
      title: 'Tickets'
    },
    title: 'Wähle, welche Tickets du bestellen möchtest:',
    next: 'WEITER',
    workshopTicket: {
      description: 'Mit diesen Tickets können Sie an einem Schokoladen-Workshop teilnehmen und erhalten Sie Zugang zu Chocolate Nation',
      title: 'Tickets inklusiv Workshop',
      notice: '(Bitte beachten Sie: Die Workshops werden nur auf Niederländisch und Englisch abgehalten.)'
    }
  },
  shoppingCart: {
    subtitleTicket: 'ticket(s)',
    subtitleTotal: 'Gesamt',
    emptySubtitle: 'Keine Tickets ausgewählt!',
    emptyMessage: 'Du hast derzeit keine Tickets in deinem Warenkorb. Hier kannst du deine Tickets auswählen.',
    emptyMessageTitle: 'Warenkorb ist leer!',
    title: 'Mein Warenkorb',
    maximumAmountReached: 'Sie können für diesen Teil nicht mehr als {0} auswählen',
    notice: {
      title: 'Hinzugefügt!',
      description: 'Klicken Sie auf den Warenkorb, um ihn zu öffnen.'
    }
  },
  ticket: {
    addButton: 'HINZUFÜGEN'
  },
  voucher: {
    button: 'Einreichen',
    placeholder: 'Gutschein Code'
  },
  workShopTicketPage: {
    title: 'Wählen Sie Ihren Workshop',
    minimumTicketSelection: 'Wählen Sie mindestens 1 Ticket, um fortzufahren.',
    voucherNotValid: 'Dieser Gutscheincode ist nicht gültig in Kombination mit einem Workshop',
    voucherOnlyValid: 'Dieser Gutscheincode ist nur gültig in Kombination mit Workshop-Tickets',
    invalidMessage: 'Gutscheincode nicht gültig',
    voucherErrorMessage: 'Gutscheininformationen konnten nicht abgerufen werden, bitte versuchen Sie es später erneut'
  },
  giftCardTicketPage: {
    title: 'Wähle deine Gutscheine aus',
    minimumTicketSelection: 'Wähle mindestens 1 Ticket aus, um fortzufahren. Für Kinder unter 4 Jahren ist keine (Zeitfenster-)Reservierung erforderlich. Sie dürfen mit ihren Begleitpersonen hinein.'
  },
  adminPage: {
    title: 'Administrationsbereich',
    addEmail: {
      title: 'E-Mail hinzufügen',
      button: 'Hinzufügen',
      newEmail: 'Neue Email Adresse',
      successMessage: '{0} wird hinzugefügt und erhält automatisch eine E-Mail mit Anmeldedaten',
      removeMessage: '{0} ist verwijderd'
    },
    allEmails: {
      title: 'Alle E-Mails',
      button: 'Löschen'
    }
  },
  completePage: {
    title: 'Bestellung abschließen',
    order: {
      title: 'Befehl',
      ticket: 'Fahrkarte',
      amount: 'Menge',
      price: 'Gesamtmenge'
    },
    totalPrice: 'Gesamt:',
    dateTime: 'Datum:',
    checkBox: 'Ich stimme einer Bestellung zu. Die Bestellungen werden<br> am Ende jedes Monats gesammelt und auf einmal in Rechnung gestellt.',
    button: 'Bestellung aufgeben',
    finishOrder: {
      title: 'Bestellung abschließen',
      description: 'Ihre Bestellung wurde aufgegeben und abgeschlossen. Sie erhalten Ihre E-Tickets innerhalb von 10 Minuten in Ihrem Posteingang.',
      button: 'Neue Bestellung aufgeben'
    }
  },
  loginPage: {
    title: 'Anmeldung',
    email: 'Email:',
    password: 'Passwort:',
    button: 'Anmeldung',
    companyDetails: {
      title: 'Firmeninformation',
      contactPerson: '* Gesprächspartner',
      companyName: '* Name der Firma',
      address: '* Adresse',
      zipCode: '* Postleitzahl',
      city: '* Ort',
      vatNumber: 'Umsatzsteuer-Identifikationsnummer',
      password: {
        title: 'Passwort',
        password: '* Neues Kennwort',
        repeat: '* Wiederhole das neue Passwort'
      },
      checkBox: 'Ich stimme den <a href="http://www.thebelgianchocolateexperience.be/algemenevoorwaarden" target="_blank">Nutzungsbedingungen</a> zu',
      notice: {
        title: 'Erste Anmeldung',
        description: 'Willkommen beim Geschäftsportal von Chocolate Nation!<br><br> Da Sie sich zum ersten Mal in unser System einloggen, müssen Sie Ihre Firmendaten eingeben und Ihr Passwort ändern. Dies ist nur bei der ersten Anmeldung erforderlich.<br><br> Vielen Dank für Ihr Verständnis.',
        button: 'Speichern'
      }
    }
  },
  marketingQuestion: {
    question: 'Wie sind Sie zu uns gekommen?',
    default: 'Wählen Sie eine Quelle/Medium',
    answers: [
      {
        value: 'facebook',
        name: 'Facebook'
      },
      {
        value: 'instagram',
        name: 'Instagram'
      },
      {
        value: 'tiktok',
        name: 'TikTok'
      },
      {
        value: 'google',
        name: 'Google'
      },
      {
        value: 'tripadvisor',
        name: 'Tripadvisor'
      },
      {
        value: 'via-via',
        name: 'Via via'
      },
      {
        value: 'hotel',
        name: 'Vom Hotel empfohlen'
      },
      {
        value: 'tijdschrift-krant-tv',
        name: 'Zeitschrift/Zeitung/Fernsehen'
      },
      {
        value: 'toeristische-dienst',
        name: 'Touristeninformation'
      }
    ]
  },
  extraQuestions: {
    group_type: 'Um welche Art von Gruppe handelt es sich?',
    primary_school: 'Grundschule',
    secondary_school: 'Mittelschule',
    family_group: 'Familiengruppe',
    students_group: 'Gruppe von Studenten',
    friends_group: 'Gruppe von Freunden',
    bachelor_group: 'Bachelor(ette)-Gruppe',
    retirees_group: 'Gruppe von Rentnern',
    disabilities_group: 'Gruppe von Menschen mit Behinderungen',
    other: 'Andere',
    disabilities: 'Gibt es Menschen, die Hilfe benötigen oder Schwierigkeiten beim Gehen haben?',
    yes: 'Ja',
    no: 'Nein',
    disabilities_amount: 'Wie viele Personen betrifft es?',
    warning: 'ACHTUNG! Die Gruppe muss zur gebuchten Zeit eintreffen. Nicht zu früh oder zu spät. In diesem Fall können wir Ihren Zugang leider nicht garantieren.'
  },
  countries: {
    choose: 'Land auswählen',
    af: 'Afghanistan',
    ax: 'Ålandinseln',
    al: 'Albanien',
    dz: 'Algerien',
    as: 'Amerikanisch-Samoa',
    ad: 'Andorra',
    ao: 'Angola',
    ai: 'Anguilla',
    aq: 'Antarktis',
    ag: 'Antigua und Barbuda',
    ar: 'Argentinien',
    am: 'Armenien',
    aw: 'Aruba',
    au: 'Australien',
    at: 'Österreich',
    az: 'Aserbaidschan',
    bs: 'Bahamas',
    bh: 'Bahrain',
    bd: 'Bangladesch',
    bb: 'Barbados',
    by: 'Belarus',
    be: 'Belgien',
    bz: 'Belize',
    bj: 'Benin',
    bm: 'Bermuda',
    bt: 'Bhutan',
    bo: 'Bolivien',
    bq: 'Bonaire, Sint Eustatius und Saba',
    ba: 'Bosnien und Herzegowina',
    bw: 'Botswana',
    bv: 'Bouvetinsel',
    br: 'Brasilien',
    io: 'Britisches Territorium im Indischen Ozean',
    bn: 'Brunei Darussalam',
    bg: 'Bulgarien',
    bf: 'Burkina Faso',
    bi: 'Burundi',
    kh: 'Kambodscha',
    cm: 'Kamerun',
    ca: 'Kanada',
    cv: 'Kap Verde',
    ky: 'Kaimaninseln',
    cf: 'Zentralafrikanische Republik',
    td: 'Tschad',
    cl: 'Chile',
    cn: 'China',
    cx: 'Weihnachtsinsel',
    cc: 'Kokosinseln',
    co: 'Kolumbien',
    km: 'Komoren',
    cg: 'Kongo',
    cd: 'Kongo, Demokratische Republik Kongo',
    ck: 'Cookinseln',
    cr: 'Costa Rica',
    ci: 'Elfenbeinküste',
    hr: 'Kroatien',
    cu: 'Kuba',
    cw: 'Curaçao',
    cy: 'Zypern',
    cz: 'Tschechische Republik',
    dk: 'Dänemark',
    dj: 'Dschibuti',
    dm: 'Dominica',
    do: 'Dominikanische Republik',
    ec: 'Ecuador',
    eg: 'Ägypten',
    sv: 'El Salvador',
    gq: 'Äquatorialguinea',
    er: 'Eritrea',
    ee: 'Estland',
    et: 'Äthiopien',
    fk: 'Falklandinseln (Malvinen)',
    fo: 'Färöer-Inseln',
    fj: 'Fidschi',
    fi: 'Finnland',
    fr: 'Frankreich',
    gf: 'Französisch-Guayana',
    pf: 'Französisch-Polynesien',
    tf: 'Französische Süd- und Antarktisgebiete',
    ga: 'Gabun',
    gm: 'Gambia',
    ge: 'Georgia',
    de: 'Germany',
    gh: 'Ghana',
    gi: 'Gibraltar',
    gr: 'Greece',
    gl: 'Greenland',
    gd: 'Grenada',
    gp: 'Guadeloupe',
    gu: 'Guam',
    gt: 'Guatemala',
    gg: 'Guernsey',
    gn: 'Guinea',
    gw: 'Guinea-Bissau',
    gy: 'Guyana',
    ht: 'Haiti',
    hm: 'Heard Island and Mcdonald Islands',
    va: 'Heiliger Stuhl (Vatikanstadt)',
    hn: 'Honduras',
    hk: 'Hongkong',
    hu: 'Ungarn',
    is: 'Island',
    in: 'Indien',
    id: 'Indonesien',
    ir: 'Iran, Islamische Republik',
    iq: 'Irak',
    ie: 'Irland',
    im: 'Insel Man',
    il: 'Israel',
    it: 'Italien',
    jm: 'Jamaika',
    jp: 'Japan',
    je: 'Jersey',
    jo: 'Jordanien',
    kz: 'Kasachstan',
    ke: 'Kenia',
    ki: 'Kiribati',
    kp: 'Korea, Demokratische Volksrepublik',
    kr: 'Korea, Republik',
    xk: 'Kosovo',
    kw: 'Kuwait',
    kg: 'Kirgisistan',
    la: 'Laos, Demokratische Volksrepublik',
    lv: 'Lettland',
    lb: 'Libanon',
    ls: 'Lesotho',
    lr: 'Liberia',
    ly: 'Libysch-Arabische Dschamahirija',
    li: 'Liechtenstein',
    lt: 'Litauen',
    lu: 'Luxemburg',
    mo: 'Macao',
    mk: 'Mazedonien, ehemalige jugoslawische Republik',
    mg: 'Madagaskar',
    mw: 'Malawi',
    my: 'Malaysia',
    mv: 'Malediven',
    ml: 'Mali',
    mt: 'Malta',
    mh: 'Marshallinseln',
    mq: 'Martinique',
    mr: 'Mauretanien',
    mu: 'Mauritius',
    yt: 'Mayotte',
    mx: 'Mexiko',
    fm: 'Mikronesien, Föderierte Staaten von',
    md: 'Moldawien, Republik',
    mc: 'Monaco',
    mn: 'Mongolei',
    me: 'Montenegro',
    ms: 'Montserrat',
    ma: 'Marokko',
    mz: 'Mosambik',
    mm: 'Myanmar',
    na: 'Namibia',
    nr: 'Nauru',
    np: 'Nepal',
    nl: 'Niederlande',
    an: 'Niederländische Antillen',
    nc: 'Neukaledonien',
    nz: 'Neuseeland',
    ni: 'Nicaragua',
    ne: 'Niger',
    ng: 'Nigeria',
    nu: 'Niue',
    nf: 'Norfolkinsel',
    mp: 'Nördliche Marianen',
    no: 'Norwegen',
    om: 'Oman',
    pk: 'Pakistan',
    pw: 'Palau',
    ps: 'Palästinensisches Territorium, besetzt',
    pa: 'Panama',
    pg: 'Papua-Neuguinea',
    py: 'Paraguay',
    pe: 'Peru',
    ph: 'Philippinen',
    pn: 'Pitcairn',
    pl: 'Polen',
    pt: 'Portugal',
    pr: 'Puerto Rico',
    qa: 'Katar',
    re: 'Wiedersehen',
    ro: 'Rumänien',
    ru: 'Russische Föderation',
    rw: 'Ruanda',
    bl: 'Heiliger Barthelemäus',
    sh: 'Heilige Helena',
    kn: 'St. Kitts und Nevis',
    lc: 'Heilige Lucia',
    mf: 'Heiliger Martin',
    pm: 'St. Pierre und Miquelon',
    vc: 'Saint Vincent und die Grenadinen',
    ws: 'Samoa',
    sm: 'San Marino',
    st: 'Sao Tome und Principe',
    sa: 'Saudi-Arabien',
    sn: 'Senegal',
    rs: 'Serbien',
    cs: 'Serbien und Montenegro',
    sc: 'Seychellen',
    sl: 'Sierra Leone',
    sg: 'Singapur',
    sx: 'Sint Maarten',
    sk: 'Slowakei',
    si: 'Slowenien',
    sb: 'Salomonen',
    so: 'Somalia',
    za: 'Südafrika',
    gs: 'Südgeorgien und die Südlichen Sandwichinseln',
    ss: 'Südsudan',
    es: 'Spanien',
    lk: 'Sri Lanka',
    sd: 'Sudan',
    sr: 'Suriname',
    sj: 'Spitzbergen und Jan Mayen',
    sz: 'Swasiland',
    se: 'Schweden',
    ch: 'Schweiz',
    sy: 'Arabische Republik Syrien',
    tw: 'Taiwan, Provinz China',
    tj: 'Tadschikistan',
    tz: 'Tansania, Vereinigte Republik',
    th: 'Thailand',
    tl: 'Timor-Leste',
    tg: 'Togo',
    tk: 'Tokelau',
    to: 'Tonga',
    tt: 'Trinidad und Tobago',
    tn: 'Tunesien',
    tr: 'Türkei',
    tm: 'Turkmenistan',
    tc: 'Turks- und Caicosinseln',
    tv: 'Tuvalu',
    ug: 'Uganda',
    ua: 'Ukraine',
    ae: 'Vereinigte Arabische Emirate',
    gb: 'Vereinigtes Königreich',
    us: 'Vereinigte Staaten',
    um: 'Kleinere abgelegene Inseln der Vereinigten Staaten',
    uy: 'Uruguay',
    uz: 'Usbekistan',
    vu: 'Vanuatu',
    ve: 'Venezuela',
    vn: 'Vietnam',
    vg: 'Jungferninseln, Britisch',
    vi: 'Jungferninseln, USA',
    wf: 'Wallis und Futuna',
    eh: 'Westsahara',
    ye: 'Jemen',
    zm: 'Sambia',
    zw: 'Simbabwe'
  }
};

export default german;
