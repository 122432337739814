
import Header from '@/components/General/Header.vue';
import Loading from '@/components/General/Loading.vue';
import tickets from '@/components/Ticket/Tickets.vue';
import DelegateLoader from '@/mixins/DelegateLoader';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { GIFTCARD_ROUTES, NOCTURNES_ROUTES, TICKET_ROUTES, WORKSHOP_ROUTES } from '@/constants/routeNames';
import { RouteConfig } from 'vue-router';
import { MUTATIONS } from '@/constants/mutationNames';

@Component({
  components: {
    Loading,
    tickets,
    Header,
  },
})
export default class SelectComponent extends Mixins(DelegateLoader) {

  public routes: RouteConfig[] = [];

  public selected: 'workshops' | 'giftcards' | 'tickets' | 'nocturnes' | '' = '';

  public mounted() {
    const currentRoute = this.$router.currentRoute.path.replace('/', '');

    if (currentRoute !== '') {
      this.$i18n.locale = currentRoute;
    }
  }

  @Watch('selected', {immediate: false, deep: true})
  public async onTicketTypeChange() {
    switch (this.selected) {
      case 'giftcards':
        this.$router.push({
          name: GIFTCARD_ROUTES.ROUTE_TICKETS
        });
        break;
      case 'tickets':
        this.$router.push({
          name: TICKET_ROUTES.ROUTE_TICKETS
        });
        break;
      case 'workshops':
        this.$router.push({
          name: WORKSHOP_ROUTES.ROUTE_TICKETS
        });
        break;
      case 'nocturnes':
        this.$router.push({
          name: NOCTURNES_ROUTES.ROUTE_TICKETS
        });
        break;
      default:
        this.$router.push({
          name: TICKET_ROUTES.ROUTE_TICKETS
        });
    }

    await this.cleanVoucherTickets();
  }

  public async cleanVoucherTickets() {
    this.$store.commit(MUTATIONS.SET_URL_ACTION_TICKETS, []);
  }
}
