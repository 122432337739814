import { ACTIONS } from '@/constants/actionNames';
import { API_ERROR_CODES } from '@/constants/apiErrorCodes';
import { API_ROUTES } from '@/constants/apiRoutes';
import { GETTERS } from '@/constants/getterNames';
import { MUTATIONS } from '@/constants/mutationNames';
import { IBusinessNaw } from '@/interfaces/IBusinessNaw';
import INaw from '@/interfaces/INaw';
import ISelectedTickets from '@/interfaces/ISelectedTickets';
import { IState } from '@/interfaces/IState';
import ITime from '@/interfaces/ITime';
import AXIOS from '@/utils/apiUtil';
import { ActionContext } from 'vuex';
import { ERROR_MESSAGES } from '@/constants/errorMessages';
import IError from '@/interfaces/IError';
import { IUrlActionCode } from '@/interfaces/IUrlActionCode';
import { i18n } from '@/i18n';
import ITicket from '@/interfaces/ITicket';

interface ISelectedFormatted {
  id: number;
  amount: number;
  isArticle?: boolean;
  voucher?: string[];
  urlActionCode?: string;
}

interface ITicketDateFormat {
  ticketId: number;
  amount: number;
}

export default {
  [ACTIONS.FETCH_TICKETS](context: ActionContext<IState, any>, currentLanguage: string) {
    let cultureCode = '';
    let groupId = 0;

    switch (currentLanguage) {
      case 'en':
        cultureCode = 'en-GB';
        groupId = 2;
        break;
      case 'de':
        cultureCode = 'de-DE';
        groupId = 17;
        break;
      case 'nl':
        cultureCode = 'nl-NL';
        groupId = 1;
        break;
      default:
        cultureCode = 'fr-FR';
        groupId = 3;
        break;
    }

    context.state.sellTickets = [];

    AXIOS.get(
      `${API_ROUTES.ROUTE_TICKETS}${groupId}/${cultureCode}`,
    ).then((res) => {
      if (res.status === API_ERROR_CODES.REQUEST_OK) {
        context.state.sellTickets = res.data.data;
      }
    }).catch((res) => {
      // TODO: implement catch
    });
  },
  [ACTIONS.FETCH_ARTICLES](context: ActionContext<IState, any>, currentLanguage: string) {
    let cultureCode = '';

    switch (currentLanguage) {
      case 'en':
        cultureCode = 'en-GB';
        break;
      case 'de':
        cultureCode = 'de-DE';
        break;
      case 'nl':
        cultureCode = 'nl-NL';
        break;
      default:
        cultureCode = 'fr-FR';
        break;
    }

    context.state.articles = [];

    AXIOS.get(
      `${API_ROUTES.ROUTE_TICKETS}14/${cultureCode}`,
    ).then((res) => {
      if (res.status === API_ERROR_CODES.REQUEST_OK) {
        res.data.data.forEach((a: ITicket) => context.state.articles.push({...a, article: true}));
      }
    }).catch((res) => {
      // TODO: implement catch
    });
  },
  [ACTIONS.FETCH_GITFTCARDS](context: ActionContext<IState, any>, currentLanguage: string) {
    let cultureCode = '';
    let groupId = 0;

    switch (currentLanguage) {
      case 'en':
        cultureCode = 'en-GB';
        groupId = 11;
        break;
      case 'de':
        cultureCode = 'de-DE';
        groupId = 18;
        break;
      case 'nl':
        cultureCode = 'nl-NL';
        groupId = 5;
        break;
      default:
        cultureCode = 'fr-FR';
        groupId = 10;
        break;
    }

    context.state.giftcards = [];

    AXIOS.get(
      `${API_ROUTES.ROUTE_GIFTCARDS}${groupId}/${cultureCode}`,
    ).then((res) => {
      if (res.status === API_ERROR_CODES.REQUEST_OK) {
        context.state.giftcards = res.data.data;
      }
    }).catch((res) => {
      // TODO: implement catch
    });
  },
  [ACTIONS.FETCH_WORKSHOPS](context: ActionContext<IState, any>, currentLanguage: string) {
    let cultureCode = '';
    const groupId = 8;

    switch (currentLanguage) {
      case 'en':
        cultureCode = 'en-GB';
        break;
      case 'nl':
        cultureCode = 'nl-NL';
        break;
      case 'de':
        cultureCode = 'de-DE';
        break;
      default:
        cultureCode = 'fr-FR';
        break;
    }

    context.state.workshops = [];

    AXIOS.get(
      `${API_ROUTES.ROUTE_WORKSHOPS}${groupId}/${cultureCode}`,
    ).then((res) => {
      if (res.status === API_ERROR_CODES.REQUEST_OK) {
        context.state.workshops = res.data.data;
      }
    }).catch((res) => {
      // TODO: implement catch
    });
  },
  [ACTIONS.FETCH_NOCTURNES](context: ActionContext<IState, any>, currentLanguage: string) {
    let cultureCode = '';
    const groupId = 20;

    switch (currentLanguage) {
      case 'en':
        cultureCode = 'en-GB';
        break;
      case 'nl':
        cultureCode = 'nl-NL';
        break;
      case 'de':
        cultureCode = 'de-DE';
        break;
      default:
        cultureCode = 'fr-FR';
        break;
    }

    context.state.nocturnes = [];

    AXIOS.get(
      `${API_ROUTES.ROUTE_NOCTURNES}${groupId}/${cultureCode}`,
    ).then((res) => {
      if (res.status === API_ERROR_CODES.REQUEST_OK) {
        context.state.nocturnes = res.data.data;
      }
    }).catch((res) => {
      // TODO: implement catch
    });
  },
  [ACTIONS.ADMIN_ADD_EMAIL](context: ActionContext<IState, any>, email: string) {
    const authToken: string = context.getters[GETTERS.GET_AUTH_TOKEN];

    return AXIOS.post(API_ROUTES.ROUTE_ADD_EMAIL, {
      email
    }, {
      headers: {
        Authentication: authToken,
      },
    });
  },
  [ACTIONS.ADMIN_GET_USERS](context: ActionContext<IState, any>) {
    const authToken: string = context.getters[GETTERS.GET_AUTH_TOKEN];

    return AXIOS.get(API_ROUTES.ROUTE_GET_USERS, {
      headers: {
        Authentication: authToken,
      }
    });
  },
  [ACTIONS.ADMIN_REMOVE_EMAIL](context: ActionContext<IState, any>, email: string) {
    const authToken: string = context.getters[GETTERS.GET_AUTH_TOKEN];

    return AXIOS.post(API_ROUTES.ROUTE_REMOVE_EMAIL, {
      email
    }, {
      headers: {
        Authentication: authToken,
      }
    });
  },
  [ACTIONS.USER_CREATE_ORDER](context: ActionContext<IState, any>, currentLanguage: string) {
    const authToken: string = context.getters[GETTERS.GET_AUTH_TOKEN];
    const timeId: ITime = context.getters[GETTERS.GET_TIME_SLOT];
    const tickets: ISelectedTickets[] = context.getters[GETTERS.GET_SELECTED_TICKETS];
    const articles: ISelectedTickets[] = context.getters[GETTERS.GET_SELECTED_ARTICLES];
    const ticketsFormatted: ISelectedFormatted[] = [];
    const articlesFormatted: ISelectedFormatted[] = [];

    tickets.forEach((ticket: ISelectedTickets) => {
      ticketsFormatted.push({
        id: ticket.id,
        amount: ticket.amount
      });
    });

    articles.forEach((article: ISelectedTickets) => {
      articlesFormatted.push({
        id: article.id,
        amount: article.amount,
        isArticle: true
      });
    });

    return AXIOS.post(
      API_ROUTES.ROUTE_USER_CREATE_ORDER,
      {
        tickets: ticketsFormatted,
        articles: articlesFormatted,
        timeId: timeId.data[0].id,
        visitDate: timeId.dateTime,
        language: currentLanguage,
      }, {
        headers: {
          Authentication: authToken
        }
      },
    );
  },
  [ACTIONS.CREATE_ORDER](context: ActionContext<IState, any>, currentLanguage: string) {
    const timeId: ITime = context.getters[GETTERS.GET_TIME_SLOT];
    const tickets: ISelectedTickets[] = context.getters[GETTERS.GET_SELECTED_TICKETS];
    const articles: ISelectedTickets[] = context.getters[GETTERS.GET_SELECTED_ARTICLES];
    const naw: INaw = context.getters[GETTERS.GET_NAW];
    const ticketsFormatted: ISelectedFormatted[] = [];
    const articlesFormatted: ISelectedFormatted[] = [];

    tickets.forEach((ticket: ISelectedTickets) => {
      let urlActionCode = '';
      const voucherCodes: string[] = [];

      context.state.voucherCodes.forEach((voucher: IUrlActionCode) => {
        if (voucher.id === ticket.id) {
          voucherCodes.push(voucher.code);
        }
      });

      context.state.urlActionCodes.forEach((code: IUrlActionCode) => {
        if (ticket.id === code.id) {
          urlActionCode = code.code;
        }
      });

      // @ts-ignore
      ticketsFormatted.push({
        id: ticket.id,
        amount: ticket.amount,
        voucher: voucherCodes,
        urlActionCode,
      });
    });

    articles.forEach((article: ISelectedTickets) => {
      articlesFormatted.push({
        id: article.id,
        amount: article.amount,
        isArticle: true
      });
    });

    let orderDataObject: any = {
      naw,
      tickets: ticketsFormatted,
      articles: articlesFormatted,
      language: currentLanguage,
    };

    if (typeof timeId.data !== 'undefined') {
      orderDataObject = {
        ...orderDataObject,
        timeId: timeId.data[0].id,
        visitDate: timeId.dateTime,
        language: currentLanguage
      };
    }

    return AXIOS.post(
      API_ROUTES.ROUTE_CREATE_ORDER,
      orderDataObject,
    );
  },
  [ACTIONS.USER_LOGIN](
    context: ActionContext<IState, any>,
    userData: { email: string, password: string },
  ) {
    return AXIOS.post(
      API_ROUTES.ROUTE_AUTHENTICATE,
      {
        email: userData.email,
        password: userData.password,
      },
    );
  },
  [ACTIONS.FETCH_TIMES](context: ActionContext<IState, any>, { time, locationId }: { time: Date, locationId: number }) {
    const currentDates = context.getters[GETTERS.GET_AVAILABLE_DATES];
    context.commit(MUTATIONS.SET_AVAILABLE_DATES, []);

    let year = time.getUTCFullYear();
    let month = time.getUTCMonth() + 6;

    if (month > 11) {
      month = month - 11;
      year += 1;
    }

    const tickets: ITicketDateFormat[] = [];

    context.state.selectedTickets.forEach((ticket: ISelectedTickets) => {
      tickets.push({
        ticketId: ticket.item.id,
        amount: ticket.amount,
      });
    });

    const startTime = `${time.getUTCFullYear()}-${('0' + (time.getUTCMonth() + 1)).slice(-2)}-01T00:00:00Z`;
    const endTime = `${year}-${('0' + month).slice(-2)}-01T00:00:00Z`;

    AXIOS.post(
      `${API_ROUTES.ROUTE_TICKET_TIMES}${locationId}`,
      {
        startTime,
        endTime,
        tickets,
      },
    ).then((res) => {
      if (res.status === 200) {
        if (typeof res.data.data !== 'undefined' && res.data.data.length !== 0) {
          context.commit(MUTATIONS.SET_AVAILABLE_DATES, res.data.data);
        } else {
          context.commit(MUTATIONS.SET_ERROR, {
            code: API_ERROR_CODES.REQUEST_BAD,
            message: i18n.t(ERROR_MESSAGES.NO_TIMES_AVAILABLE),
          } as IError);

          context.commit(MUTATIONS.SET_AVAILABLE_DATES, currentDates);
        }
      }
    }).catch((res) => {
      context.commit(MUTATIONS.SET_ERROR, {
        code: API_ERROR_CODES.REQUEST_BAD,
        message: i18n.t(ERROR_MESSAGES.NO_TIMES_AVAILABLE),
      } as IError);
      context.commit(MUTATIONS.SET_AVAILABLE_DATES, currentDates);
    });
  },
  async [ACTIONS.USER_CHANGE_PASSWORD](
    context: ActionContext<IState, any>,
    password: string,
  ) {
    const authToken: string = context.getters[GETTERS.GET_AUTH_TOKEN];

    AXIOS.post(
      API_ROUTES.ROUTE_CHANGE_PASSWORD,
      {
        password,
      }, {
        headers: {
          Authentication: authToken,
        },
      },
    ).then((res) => {
      if (res.status === API_ERROR_CODES.REQUEST_OK && res.data.ok) {
        return true;
      } else {
        throw new Error();
      }
    }).catch(() => {
      throw new Error();
    });
  },
  async [ACTIONS.USER_SET_NAW](
    context: ActionContext<IState, any>,
    nawData: IBusinessNaw,
  ) {
    const authToken: string = context.getters[GETTERS.GET_AUTH_TOKEN];

    return AXIOS.post(
      API_ROUTES.ROUTE_USER_SET_NAW,
      {
        contactPerson: nawData.fullName,
        businessName: nawData.businessName,
        address: nawData.address,
        zipCode: nawData.zipCode,
        city: nawData.city,
        vatNumber: nawData.vatNumber,
      }, {
        headers: {
          Authentication: authToken,
        },
      },
    );
  },
};
