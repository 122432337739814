import { IDelegate } from '@/interfaces/IDelegate';
import ROUTER from '@/router';
import { ADMIN_ROUTES } from '@/constants/routeNames';

export class AdminDelegate implements IDelegate {
  public async beforeRouteEnterPayment(): Promise<any> {
    await ROUTER.push({
      name: ADMIN_ROUTES.ROUTE_ADMIN
    });

    return false;
  }

  public async beforeRouteEnterTickets(): Promise<any> {
    await ROUTER.push({
      name: ADMIN_ROUTES.ROUTE_ADMIN
    });

    return false;
  }

  public async beforeRouteEnterNaw(): Promise<any> {
    await ROUTER.push({
      name: ADMIN_ROUTES.ROUTE_ADMIN
    });

    return false;
  }

  public async beforeRouteEnterDate(): Promise<any> {
    await ROUTER.push({
      name: ADMIN_ROUTES.ROUTE_ADMIN
    });

    return false;
  }

  public fetchTickets(): void {
    // no need to implement
  }

  public fetchArticles(): void {
    // no need to implement
  }

  public tickets(): any {
    // no need to implement
  }

  public articles(): any {
    // no need to implement
  }

  public nextTicket(): void {
    // no need to implement
  }

  public nextDate(): void {
    // no need to implement
  }

  public previousDate(): void {
    // no need to implement
  }

  public nextArticles(): void {
    // no need to implement
  }

  public previousArticles(): void {
    // no need to implement
  }

  public nextNaw(): void {
    // no need to implement
  }

  public previousNaw(): void {
    // no need to implement
  }

  public changedMonth(): void {
  }

  public loadDates(): void {

  }

}
