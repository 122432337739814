
import { Component, Mixins } from 'vue-property-decorator';
import DelegateLoader from '../../mixins/DelegateLoader';
import Loading from '@/components/General/Loading.vue';
import { ACTIONS } from '@/constants/actionNames';
import { API_ERROR_CODES } from '@/constants/apiErrorCodes';
import { MUTATIONS } from '@/constants/mutationNames';
import { ERROR_MESSAGES } from '@/constants/errorMessages';
import { GETTERS } from '@/constants/getterNames';
import { TICKET_ROUTES } from '@/constants/routeNames';

type Users = {
  email: string
}[];

@Component({
  components: {
    Loading,
  }
})
export default class CompleteComponent extends Mixins(DelegateLoader) {
  public newEmail: string = '';

  public isLoading: boolean = true;

  public message: string = '';

  public users: Users = [];

  get isAdmin(): boolean {
    return this.$store.getters[GETTERS.GET_IS_ADMIN];
  }

  public mounted() {
    if (!this.isAdmin) {
      this.$router.push({
        name: TICKET_ROUTES.ROUTE_TICKETS
      });
    }

    this.$store.dispatch(ACTIONS.ADMIN_GET_USERS).then((res) => {
      if (res.status === API_ERROR_CODES.REQUEST_OK && res.data.ok) {
        this.users = res.data.data.users;
      }
    }).catch(() => {
      this.$store.commit(MUTATIONS.SET_ERROR, {
        code: API_ERROR_CODES.REQUEST_BAD,
        message: ERROR_MESSAGES.GETTING_USERS
      });
    }).finally(() => {
      this.isLoading = false;
    });
  }

  public addEmail() {
    this.isLoading = true;
    this.message = '';

    this.$store.dispatch(ACTIONS.ADMIN_ADD_EMAIL, this.newEmail).then((res) => {
      if (res.status === API_ERROR_CODES.REQUEST_OK && res.data.ok) {
        this.message = this.$i18n.t('adminPage.addEmail.successMessage', [this.newEmail]) as string;
        this.users.push({
          email: this.newEmail
        });
      }
    }).catch(() => {
      this.$store.commit(MUTATIONS.SET_ERROR, {
        code: API_ERROR_CODES.REQUEST_BAD,
        message: ERROR_MESSAGES.EMAIL_NOT_ADDED
      });
    }).finally(() => {
      this.isLoading = false;
    });
  }

  public removeEmail(email: string, index: number) {
    this.isLoading = true;
    this.message = '';

    this.$store.dispatch(ACTIONS.ADMIN_REMOVE_EMAIL, email).then((res) => {
      if (res.status === API_ERROR_CODES.REQUEST_OK && res.data.ok) {
        this.message = this.$i18n.t('adminPage.addEmail.removeMessage', [email]) as string;
        this.users.splice(index, 1);
      }
    }).catch(() => {
      this.$store.commit(MUTATIONS.SET_ERROR, {
        code: API_ERROR_CODES.REQUEST_BAD,
        message: ERROR_MESSAGES.EMAIL_NOT_REMOVED
      });
    }).finally(() => {
      this.isLoading = false;
    });
  }
}
