export enum GETTERS {
  GET_TOTAL_TICKET_AMOUNT = 'GET_TOTAL_TICKET_AMOUNT',
  GET_TOTAL_PRICE = 'GET_TOTAL_PRICE',
  GET_AVAILABLE_DATES = 'GET_AVAILABLE_DATES',
  GET_ORDER_ID = 'GET_ORDER_ID',
  GET_SELECTED_TICKETS = 'GET_SELECTED_TICKETS',
  GET_SELECTED_TICKETS_FILTERED = 'GET_SELECTED_TICKETS_FILTERED',
  GET_ERROR = 'GET_ERROR',
  GET_NAW = 'GET_NAW',
  GET_TIME_SLOT = 'GET_TIME_SLOT',
  GET_TICKETS = 'GET_TICKETS',
  GET_NOCTURNES = 'GET_NOCTURNES',
  GET_GIFTCARDS = 'GET_GIFTCARDS',
  GET_IS_ADMIN = 'GET_IS_ADMIN',
  GET_IS_FIRST_LOGIN = 'GET_IS_FIRST_LOGIN',
  GET_IS_LOGGED_IN = 'GET_IS_LOGGED_IN',
  GET_AUTH_TOKEN = 'GET_AUTH_TOKEN',
  GET_WORKSHOPS = 'GET_WORKSHOPS',
  GET_VOUCHER_CODES = 'GET_VOUCHER_CODES',
  GET_ARTICLES = 'GET_ARTICLES',
  GET_SELECTED_ARTICLES = 'GET_SELECTED_ARTICLES',
  GET_URL_ACTION_TICKETS = 'GET_URL_ACTION_TICKETS',
}
