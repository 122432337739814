import { IDelegate } from '@/interfaces/IDelegate';
import { BusinessDelegate } from '@/services/BusinessDelegate';
import { GiftcardDelegate } from '@/services/GiftcardDelegate';
import { TicketDelegate } from '@/services/TicketDelegate';
import { Component, Vue } from 'vue-property-decorator';
import { AdminDelegate } from '@/services/AdminDelegate';
import { WorkshopDelegate } from '@/services/WorkshopDelegate';
import { NocturnesDelegate } from '@/services/NocturnesDelegate';

const BUSINESS = 'business';
const CADEAUBONNEN = 'cadeaubonnen';
const ADMIN = 'admin';
const WORKSHOP = 'workshop';
const NOCTURNES = 'nocturnes';

export enum ACTIVE_ROUTES {
  GIFTCARD = 'giftcard',
  BUSINESS = 'business',
  ADMIN = 'admin',
  WORKSHOP = 'workshop',
  TICKET = 'ticket',
  NOCTURNES = 'nocturnes',
}

@Component
export default class DelegateLoader extends Vue {

  public activeRoute = 'ticket';

  public delegate: IDelegate = new TicketDelegate();

  public static getActiveRouteFromRoute(route: any) {
    if (route.fullPath.indexOf(CADEAUBONNEN) !== -1) {
      return ACTIVE_ROUTES.GIFTCARD;
    } else if (route.fullPath.indexOf(BUSINESS) !== -1) {
      return ACTIVE_ROUTES.BUSINESS;
    } else if (route.fullPath.indexOf(ADMIN) !== -1) {
      return ACTIVE_ROUTES.ADMIN;
    } else if (route.fullPath.indexOf(WORKSHOP) !== -1) {
      return ACTIVE_ROUTES.WORKSHOP;
    } else if (route.fullPath.indexOf(NOCTURNES) !== -1) {
      return ACTIVE_ROUTES.NOCTURNES;
    } else {
      return ACTIVE_ROUTES.TICKET;
    }
  }

  public created() {
    if (this.$route.fullPath.indexOf(CADEAUBONNEN) !== -1) {
      this.delegate = new GiftcardDelegate();
      this.activeRoute = ACTIVE_ROUTES.GIFTCARD;
    } else if (this.$route.fullPath.indexOf(BUSINESS) !== -1) {
      this.delegate = new BusinessDelegate();
      this.activeRoute = ACTIVE_ROUTES.BUSINESS;
    } else if (this.$route.fullPath.indexOf(ADMIN) !== -1) {
      this.delegate = new AdminDelegate();
      this.activeRoute = ACTIVE_ROUTES.ADMIN;
    } else if (this.$route.fullPath.indexOf(WORKSHOP) !== -1) {
      this.delegate = new WorkshopDelegate();
      this.activeRoute = ACTIVE_ROUTES.WORKSHOP;
    } else if (this.$route.fullPath.indexOf(NOCTURNES) !== -1) {
      this.delegate = new NocturnesDelegate();
      this.activeRoute = ACTIVE_ROUTES.NOCTURNES;
    }
  }

}
