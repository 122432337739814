
import Cart from '@/components/General/Cart.vue';
import { GETTERS } from '@/constants/getterNames';
import ISelectedTickets from '@/interfaces/ISelectedTickets';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import FlagComponent from '@/components/General/FlagComponent.vue';
import translation from '@/translation';

@Component({
  components: {
    Cart,
    FlagComponent,
  },
})
export default class Header extends Vue {
  @Prop({type: Array, default: []})
  public readonly routes!: RouteConfig[];

  public cartBounce: boolean = false;

  public showCartScreen: boolean = false;

  public showCartNotice: boolean = false;

  get languages() {
    return Object.keys(translation);
  }

  get isAdmin(): boolean {
    return this.$store.getters[GETTERS.GET_IS_ADMIN];
  }

  get tickets(): ISelectedTickets[] {
    return this.$store.getters[GETTERS.GET_SELECTED_TICKETS];
  }

  get totalTickets(): number {
    return this.$store.getters[GETTERS.GET_TOTAL_TICKET_AMOUNT];
  }

  get totalPrice(): string {
    return this.$store.getters[GETTERS.GET_TOTAL_PRICE];
  }

  get currentRoute(){
    return this.$route.name;
  }

  public data() {
    return {
      showCartScreen: false,
      showCartNotice: false,
      cartBounce: false,
    };
  }

  @Watch('currentRoute', {immediate: false, deep: true})
  public testFunc() {
    this.cartBounce = false;
    this.showCartScreen = false;
    this.showCartNotice = false;
  }

  @Watch('tickets', {immediate: false, deep: true})
  public onTicketsChanged() {
    if (this.tickets.length !== 0) {
      if (!this.cartBounce && !this.showCartScreen) {
        this.cartBounce = true;
        this.showCartNotice = true;

        setTimeout(() => {
          this.cartBounce = false;
          this.showCartScreen = false;
        }, 1000);

        setTimeout(() => {
          this.showCartNotice = false;
        }, 2000);
      }
    }
  }
}
