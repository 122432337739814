import { GETTERS } from '@/constants/getterNames';
import { MUTATIONS } from '@/constants/mutationNames';
import IError from '@/interfaces/IError';
import ITicket from '@/interfaces/ITicket';
import { IUrlActionCode } from '@/interfaces/IUrlActionCode';
import DelegateLoader, { ACTIVE_ROUTES } from '@/mixins/DelegateLoader';
import AXIOS from '@/utils/apiUtil';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class VoucherMixin extends Vue {

  public voucherInput = '';

  public loading = false;

  public checkVoucherCode(): void {
    if (this.voucherInput.length > 1) {
      this.loading = true;
      const voucherCode = this.voucherInput.replace(' ', '');
      AXIOS.get(`/voucher/${voucherCode}`).then((res) => {
        this.voucherInput = '';
        this.loading = false;
        if (res.status === 200) {
          if (res.data.data.isVoucher) {
            let found = false;
            const codes: IUrlActionCode[] = this.$store.getters[GETTERS.GET_VOUCHER_CODES];

            codes.forEach((code: IUrlActionCode) => {
              if (code.code === voucherCode) {
                found = true;
              }
            });

            if (!found) {
              const ticket = res.data.data.tickets[0];

              const activeRoute = DelegateLoader.getActiveRouteFromRoute(this.$route);

              if (activeRoute === ACTIVE_ROUTES.WORKSHOP && ticket.LocationId === 1) {
                this.$store.commit(MUTATIONS.SET_ERROR, {
                  code: 404,
                  message: this.$i18n.t('workShopTicketPage.voucherNotValid'),
                } as IError);
              } else if (activeRoute === ACTIVE_ROUTES.TICKET && ticket.LocationId !== 1) {
                this.$store.commit(MUTATIONS.SET_ERROR, {
                  code: 404,
                  message: this.$i18n.t('workShopTicketPage.voucherOnlyValid'),
                } as IError);
              } else {
                this.$store.commit(MUTATIONS.ADD_TICKET, ticket);

                this.$store.commit(MUTATIONS.ADD_VOUCHER, {
                  id: ticket.id,
                  code: voucherCode,
                });
              }
            }
          } else {

            let locationId = 0;

            if (typeof res.data.data.tickets.id === 'undefined') {
              locationId = res.data.data.tickets[0].LocationId;
            } else {
              locationId = res.data.data.tickets.LocationId;
            }

            const activeRoute = DelegateLoader.getActiveRouteFromRoute(this.$route);

            if (activeRoute === ACTIVE_ROUTES.WORKSHOP && locationId === 1) {
              this.$store.commit(MUTATIONS.SET_ERROR, {
                code: 404,
                message: this.$i18n.t('workShopTicketPage.voucherNotValid'),
              } as IError);
            } else if (activeRoute === ACTIVE_ROUTES.TICKET && locationId !== 1) {
              this.$store.commit(MUTATIONS.SET_ERROR, {
                code: 404,
                message: this.$i18n.t('workShopTicketPage.voucherOnlyValid'),
              } as IError);
            } else {
              this.$store.commit(MUTATIONS.SET_URL_ACTION_TICKETS, res.data.data.tickets);

              if (typeof res.data.data.tickets.id === 'undefined') {
                res.data.data.tickets.forEach((ticket: ITicket) => {
                  this.$store.commit(MUTATIONS.ADD_URL_ACTION, {
                    id: ticket.id,
                    code: voucherCode,
                  });
                });
              } else {
                this.$store.commit(MUTATIONS.ADD_URL_ACTION, {
                  id: res.data.data.tickets.id,
                  code: voucherCode,
                });

                this.$store.commit(MUTATIONS.ADD_TICKET, res.data.data.tickets);
              }
            }
          }
        } else {
          this.voucherInput = '';
          this.$store.commit(MUTATIONS.SET_ERROR, {
            code: 404,
            message: this.$i18n.t('workShopTicketPage.invalidMessage'),
          } as IError);
        }
      }).catch((e) => {
        this.loading = false;
        this.voucherInput = '';
        this.$store.commit(MUTATIONS.SET_ERROR, {
          code: 400,
          message: this.$i18n.t('workShopTicketPage.voucherErrorMessage'),
        } as IError);
      });
    }
  }
}
