export enum ACTIONS {
  FETCH_TICKETS = 'FETCH_TICKETS',
  FETCH_ARTICLES = 'FETCH_ARTICLES',
  FETCH_GITFTCARDS = 'FETCH_GITFTCARDS',
  FETCH_DATES = 'FETCH_DATES',
  CREATE_ORDER = 'CREATE_ORDER',
  USER_LOGIN = 'USER_LOGIN',
  USER_SET_NAW = 'USER_SET_NAW',
  USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD',
  FETCH_TIMES = 'FETCH_TIMES',
  ADMIN_ADD_EMAIL = 'ADMIN_ADD_EMAIL',
  ADMIN_GET_USERS = 'ADMIN_GET_USERS',
  ADMIN_REMOVE_EMAIL = 'ADMIN_REMOVE_EMAIL',
  USER_CREATE_ORDER = 'USER_CREATE_ORDER',
  FETCH_WORKSHOPS = 'FETCH_WORKSHOPS',
  FETCH_NOCTURNES = 'FETCH_NOCTURNES',
}
