
import Loading from '@/components/General/Loading.vue';
import { GETTERS } from '@/constants/getterNames';
import ITicket from '@/interfaces/ITicket';
import tickets from '@/components/Ticket/Tickets.vue';
import DelegateLoader from '@/mixins/DelegateLoader';
import { Component, Mixins } from 'vue-property-decorator';
import VoucherMixin from '@/mixins/VoucherMixin';

@Component({
  components: {
    Loading,
    tickets,
  },
})
export default class ArticleComponent extends Mixins(DelegateLoader, VoucherMixin) {
  public voucherInput: string = '';

  get articles() {
    return this.$store.getters[GETTERS.GET_SELECTED_ARTICLES];
  }

  get tickets(): ITicket[] {
    return this.delegate.articles();
  }

  public async mounted() {
    await this.delegate.beforeRouteEnterDate();
  }

  public created() {
    this.delegate.fetchArticles();
  }

  public nextStep(): void {
    this.delegate.nextArticles();
  }

  public previousStep(): void {
    this.delegate.previousArticles();
  }
}
