
import DelegateLoader from '@/mixins/DelegateLoader';
import Datepicker from 'vuejs-datepicker';
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
import { en, fr, nl, de } from 'vuejs-datepicker/dist/locale';
import Loading from '@/components/General/Loading.vue';
import IDate from '@/interfaces/IDate';
import { GETTERS } from '@/constants/getterNames';
import ITime from '@/interfaces/ITime';
import _ from 'lodash';
import { MUTATIONS } from '@/constants/mutationNames';
import moment from 'moment-timezone';
import 'moment/locale/nl';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/de';

moment.tz.setDefault('UTC');

interface ICustomDateResponse extends Date {
  timestamp: number;
}

interface IPicker {
  value: Date;
  inline: boolean;
  show: boolean;
  timeSelected: boolean;
  disabledDates: object;
  mondayFirst: boolean;
  maxView: string;
}

@Component({
  components: {
    Loading,
    Datepicker
  },
})
export default class DateComponent extends Mixins(DelegateLoader) {

  public momentDate = moment;

  public times: ITime[] = [];

  public loading: boolean = false;

  public correctedDate: boolean = false;

  public multipleSlots: any = {};

  public highlightedDates: object = {
    dates: [],
  };

  get currentLanguage() {
    let languagePack: any = '';

    switch (this.$i18n.locale) {
      case 'en':
        languagePack = en;
        break;
      case 'fr':
        languagePack = fr;
        break;
      case 'de':
        languagePack = de;
        break;
      default:
        languagePack = nl;
        break;
    }

    return languagePack;
  }

  get availableDates() {
    return this.$store.getters[GETTERS.GET_AVAILABLE_DATES];
  }

  get availableTimes() {
    const availableTimes: any = [];

    _.forEach(this.availableDates, (data: any) => {
      _.forEach(data.times, (timeData: any) => {
        const dateTime = moment.utc(timeData.data[0].dateTime);
        if (moment.utc() <= dateTime) {
          availableTimes.push(timeData);
        }
      });
    });

    return availableTimes;
  }

  get time(): ITime {
    return this.$store.getters[GETTERS.GET_TIME_SLOT];
  }

  public correctDate = () => {
    const date: Date = new Date();
    date.setDate(date.getDate() - 1);
    this.correctedDate = true;
    return date;
  };

  public picker: IPicker = {
    value: moment.utc().toDate(),
    inline: true,
    show: true,
    timeSelected: false,
    disabledDates: {
      to: this.correctDate(),
    },
    mondayFirst: true,
    maxView: 'month',
  };

  public data() {
    return {
      loading: false,
      times: [],
      nl,
      fr,
      en,
    };
  }

  public async mounted() {
    moment().locale(this.$i18n.locale);
    this.momentDate.locale(this.$i18n.locale);
    const success = await this.delegate.beforeRouteEnterDate();

    if (success) {
      this.delegate.loadDates();
      this.setHighlightedDates();
    }
  }

  @Watch('availableDates')
  public onDatesChanged() {
    this.setHighlightedDates();
  }

  public setHighlightedDates = (): void => {
    const availableDates: [] = this.$store.getters[GETTERS.GET_AVAILABLE_DATES];
    const returnDates: Date[] = [];

    availableDates.forEach((date: IDate) => {
      const dateTime = moment.utc(date.date).startOf('day').toDate();
      returnDates.push(dateTime);
    });

    this.picker.value = returnDates[0];
    Vue.set(this.highlightedDates, 'dates', returnDates);
  };

  public changedMonth(date: ICustomDateResponse | any): void {
    this.delegate.changedMonth(date);
  }

  public selectDateNext(date: Date): void {
    this.selectDate(date);
    this.setDate(this.multipleSlots[0], 0);
    this.nextStep();
  }

  public formatWorkshopTime(dateTime: string): string {
    const date = moment.utc(dateTime);
    return date.format('DD MMMM');
  }

  public selectDate(date: Date): void {
    const utcDate = moment.utc(date).startOf('day').toDate();
    this.picker.value = utcDate;
    this.multipleSlots = {};

    for (let i = 0; i < this.availableDates.length; i++) {
      const aDates = moment.utc(this.availableDates[i].date).startOf('day').toDate();

      if (
        aDates.getFullYear() === utcDate.getFullYear()
          && aDates.getMonth() === utcDate.getMonth()
          && aDates.getDate() === utcDate.getDate()
      ) {
        if (this.correctedDate) {
          this.correctedDate = false;
        }

        this.multipleSlots = this.availableDates[i].times;
      }
    }
  }

  public setDate(time: any, key: number) {
    time.data = [time.data[key]];
    this.multipleSlots = {};
    this.$store.commit(MUTATIONS.SET_TIME, time);
  }

  public nextStep(): void {
    this.delegate.nextDate();
  }

  public previousStep(): void {
    this.delegate.previousDate();
  }
}
