export enum API_ROUTES {
  ROUTE_TICKETS = '/tickets/',
  ROUTE_ARTICLES = '/articles/',
  ROUTE_GIFTCARDS = '/tickets/',
  ROUTE_CREATE_ORDER = '/order/create',
  ROUTE_PAY_ORDER = '/order/pay/',
  ROUTE_AUTHENTICATE = '/auth/login',
  ROUTE_CHANGE_PASSWORD = '/auth/change/password',
  ROUTE_ADD_EMAIL = '/auth/add/email',
  ROUTE_REMOVE_EMAIL = '/auth/remove/email',
  ROUTE_GET_USERS = '/auth/users',
  ROUTE_USER_SET_NAW = '/auth/user/naw',
  ROUTE_TICKET_TIMES = '/time/',
  ROUTE_USER_CREATE_ORDER = '/auth/order/create',
  ROUTE_WORKSHOPS = '/tickets/',
  ROUTE_NOCTURNES = '/tickets/',
}
