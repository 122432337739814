import { ACTIONS } from '@/constants/actionNames';
import { GETTERS } from '@/constants/getterNames';
import { GIFTCARD_ROUTES } from '@/constants/routeNames';
import { IDelegate } from '@/interfaces/IDelegate';
import ITicket from '@/interfaces/ITicket';
import ROUTER from '@/router';
import store from '@/store';
import { i18n } from '@/i18n';

interface ICustomDateResponse extends Date {
  timestamp: number;
}

export class GiftcardDelegate implements IDelegate {

  public async beforeRouteEnterPayment(): Promise<boolean> {
    const hasOrder = store.getters[GETTERS.GET_ORDER_ID] !== 0;

    if (!hasOrder) {
      ROUTER.push({
        name: GIFTCARD_ROUTES.ROUTE_NAW
      });

      return false;
    }

    return true;
  }

  public async beforeRouteEnterTickets(): Promise<any> {
    const hasOrder = store.getters[GETTERS.GET_ORDER_ID] !== 0;

    if (hasOrder) {
      ROUTER.push({
        name: GIFTCARD_ROUTES.ROUTE_PAYMENT
      });

      return false;
    }

    return true;
  }

  public async beforeRouteEnterNaw(): Promise<boolean> {
    const hasTickets = store.getters[GETTERS.GET_SELECTED_TICKETS].length > 0;
    const hasOrder = store.getters[GETTERS.GET_ORDER_ID] !== 0;

    if (hasOrder) {
      ROUTER.push({
        name: GIFTCARD_ROUTES.ROUTE_PAYMENT
      });

      return false;
    }

    if (!hasTickets) {
      ROUTER.push({
        name: GIFTCARD_ROUTES.ROUTE_TICKETS
      });

      return false;
    }

    return true;
  }

  public async beforeRouteEnterDate(): Promise<boolean> {
    const hasTickets = store.getters[GETTERS.GET_SELECTED_TICKETS].length > 0;
    const hasOrder = store.getters[GETTERS.GET_ORDER_ID] !== 0;

    if (hasOrder) {
      ROUTER.push({
        name: GIFTCARD_ROUTES.ROUTE_PAYMENT
      });

      return false;
    }

    if (!hasTickets) {
      ROUTER.push({
        name: GIFTCARD_ROUTES.ROUTE_TICKETS
      });

      return false;
    }

    return true;
  }

  // Start of Ticket Component functions
  public fetchTickets(): void {
    store.dispatch(ACTIONS.FETCH_GITFTCARDS, i18n.locale);
  }

  public fetchArticles(): void {
    store.dispatch(ACTIONS.FETCH_ARTICLES, i18n.locale);
  }

  public nextTicket(): void {
    ROUTER.push({
      name: GIFTCARD_ROUTES.ROUTE_ARTICLES
    });
  }

  public tickets(): ITicket[] {
    return store.getters[GETTERS.GET_GIFTCARDS];
  }

  public articles(): ITicket[] {
    return store.getters[GETTERS.GET_ARTICLES];
  }

  // Start of Date Component functions
  public nextDate(): void {
    // Not needed to implement
  }

  public previousDate(): void {
    // Not needed to implement
  }

  public nextArticles(): void {
    ROUTER.push({
      name: GIFTCARD_ROUTES.ROUTE_NAW,
    });
  }

  public previousArticles(): void {
    ROUTER.push({
      name: GIFTCARD_ROUTES.ROUTE_TICKETS,
    });
  }


  // Start of NAW Component functions
  public nextNaw(): void {
    ROUTER.push({
      name: GIFTCARD_ROUTES.ROUTE_PAYMENT,
    });
  }

  public previousNaw(): void {
    ROUTER.push({
      name: GIFTCARD_ROUTES.ROUTE_ARTICLES,
    });
  }

  public changedMonth(date: ICustomDateResponse): void {

  }

  public loadDates(): void {

  }

}
