import { GETTERS } from '@/constants/getterNames';
import IDate from '@/interfaces/IDate';
import IError from '@/interfaces/IError';
import INaw from '@/interfaces/INaw';
import ISelectedTickets from '@/interfaces/ISelectedTickets';
import { IState } from '@/interfaces/IState';
import ITicket from '@/interfaces/ITicket';
import ITime from '@/interfaces/ITime';
import FORMAT_CURRENCY from '@/utils/currencyFormatterUtil';
import { IUrlActionCode } from '@/interfaces/IUrlActionCode';
import moment from 'moment';

export default {
  [GETTERS.GET_TOTAL_TICKET_AMOUNT](state: IState): number {
    let amount = 0;

    state.selectedTickets.forEach((selectedTicket: ISelectedTickets) => {
      amount += selectedTicket.amount;
    });

    return amount;
  },
  [GETTERS.GET_TOTAL_PRICE](state: IState): string {
    let price = 0;

    state.selectedTickets.forEach((selectedTicket: ISelectedTickets) => {
      price += selectedTicket.amount * selectedTicket.item.price;
    });

    return FORMAT_CURRENCY(price);
  },
  [GETTERS.GET_WORKSHOPS](state: IState) {
    return state.workshops;
  },
  [GETTERS.GET_VOUCHER_CODES](state: IState): IUrlActionCode[] {
    return state.voucherCodes;
  },
  [GETTERS.GET_ARTICLES](state: IState): ITicket[] {
    if (!state || !state.time || !state.time.data || !state.time.data[0]) {
      return state.articles;
    }

    const targetDateTime = moment(state.time.data[0].dateTime);

    return state.articles.filter(article => {
      const isUnavailable = article.availabilities.some(availability => {
        const notAvailableFrom = moment(availability.attributes.notAvailableFrom);
        const notAvailableUntil = moment(availability.attributes.notAvailableUntil);
        return targetDateTime.isBetween(notAvailableFrom, notAvailableUntil, undefined, '[]');
      });

      return !isUnavailable;
    });
  },
  [GETTERS.GET_IS_FIRST_LOGIN](state: IState) {
    return state.firstLogin;
  },
  [GETTERS.GET_AUTH_TOKEN](state: IState) {
    return state.authToken;
  },
  [GETTERS.GET_TIME_SLOT](state: IState): ITime {
    return state.time;
  },
  [GETTERS.GET_IS_LOGGED_IN](state: IState): boolean {
    return state.isLoggedIn;
  },
  [GETTERS.GET_AVAILABLE_DATES](state: IState): IDate[] {
    return state.availableDates;
  },
  [GETTERS.GET_ORDER_ID](state: IState): number {
    return state.orderId;
  },
  [GETTERS.GET_ERROR](state: IState): IError {
    return state.error;
  },
  [GETTERS.GET_NAW](state: IState): INaw {
    return state.naw;
  },
  [GETTERS.GET_IS_ADMIN](state: IState): boolean {
    return state.isAdmin;
  },
  [GETTERS.GET_TICKETS](state: IState): ITicket[] {
    return state.sellTickets;
  },
  [GETTERS.GET_NOCTURNES](state: IState): ITicket[] {
    return state.nocturnes;
  },
  [GETTERS.GET_GIFTCARDS](state: IState): ITicket[] {
    return state.giftcards;
  },
  [GETTERS.GET_SELECTED_TICKETS](state: IState): ISelectedTickets[] {
    return state.selectedTickets.filter(t => !t.item.article);
  },
  [GETTERS.GET_SELECTED_ARTICLES](state: IState): ISelectedTickets[] {
    return state.selectedTickets.filter(t => t.item.article);
  },
  [GETTERS.GET_URL_ACTION_TICKETS](state: IState): ITicket[] {
    return state.urlActionTickets;
  },
};
