import { MUTATIONS } from '@/constants/mutationNames';
import IError from '@/interfaces/IError';
import INaw from '@/interfaces/INaw';
import ISelectedTickets from '@/interfaces/ISelectedTickets';
import { IState } from '@/interfaces/IState';
import ITicket from '@/interfaces/ITicket';
import Vue from 'vue';

interface UrlActionPayload {
  id: number;
  code: string;
}

export default {
  [MUTATIONS.SET_ERROR](state: IState, error: IError) {
    state.error = error;
  },
  [MUTATIONS.IS_FIRST_LOGIN](state: IState, isFirstLogin: boolean) {
    state.firstLogin = isFirstLogin;
  },
  [MUTATIONS.SET_IS_ADMIN](state: IState, isAdmin: boolean) {
    state.isAdmin = isAdmin;
  },
  [MUTATIONS.SET_IS_LOGGED_IN](state: IState, isLoggedIn: boolean) {
    state.isLoggedIn = isLoggedIn;
  },
  [MUTATIONS.SET_AUTH_TOKEN](state: IState, authToken: string) {
    state.authToken = authToken;
  },
  [MUTATIONS.ADD_TICKET](state: IState, ticket: ITicket) {
    let added = false;

    if (state.selectedTickets.length > 0) {
      if (state.selectedTickets[0].item.LocationId !== ticket.LocationId && !ticket.article) {
        return false;
      }
    }

    state.selectedTickets.forEach((selectedTicket: ISelectedTickets) => {
      if (selectedTicket.id === ticket.id) {
        added = true;
        if (selectedTicket.amount < ticket.maxTicketsOnce || ticket.article) {
          selectedTicket.amount += 1;
        }
      }
    });

    if (!added) {
      const object: ISelectedTickets = {
        amount: (ticket.minOnce === 0 ? 1 : ticket.minOnce),
        id: ticket.id,
        item: ticket,
      };
      state.selectedTickets.push(object);
    }
  },
  [MUTATIONS.REMOVE_TICKET](state: IState, ticket: ITicket) {
    state.selectedTickets.forEach(
      (selectedTicket: ISelectedTickets, index: number) => {
        if (selectedTicket.id === ticket.id) {
          if (ticket.minOnce < selectedTicket.amount) {
            if (selectedTicket.amount === 1) {
              state.selectedTickets.splice(index, 1);
            } else {
              selectedTicket.amount -= 1;
            }
          } else {
            state.selectedTickets.splice(index, 1);
          }
        }
      },
    );
  },
  [MUTATIONS.REMOVE_TICKET_GROUP](state: IState, ticket: ITicket) {
    state.selectedTickets.forEach(
      (selectedTicket: ISelectedTickets, index: number) => {
        if (selectedTicket.id === ticket.id) {
          state.selectedTickets.splice(index, 1);
        }
      },
    );
  },
  [MUTATIONS.ADD_URL_ACTION](state: IState, payload: UrlActionPayload) {
    state.urlActionCodes.push({
      id: payload.id,
      code: payload.code,
    });
  },
  [MUTATIONS.ADD_VOUCHER](state: IState, payload: UrlActionPayload) {
    state.voucherCodes.push({
      id: payload.id,
      code: payload.code,
    });
  },
  [MUTATIONS.SET_URL_ACTION_TICKETS](state: IState, tickets: ITicket[]) {
    state.urlActionTickets = tickets;
  },
  [MUTATIONS.SET_ARTICLES](state: IState, articles: ITicket[]) {
    state.articles = articles;
  },
  [MUTATIONS.SET_ERROR](state: IState, error: IError) {
    Vue.set(state, 'error', error);
  },
  [MUTATIONS.SET_AVAILABLE_DATES](state: IState, times: []) {
    state.availableDates = times;
  },
  [MUTATIONS.SET_NAW](state: IState, naw: INaw) {
    Vue.set(state, 'naw', naw);
  },
  [MUTATIONS.SET_ORDER_ID](state: IState, id: number) {
    Vue.set(state, 'orderId', id);
  },
  [MUTATIONS.SET_TIME](state: IState, time: any) {
    Vue.set(state, 'time', time);
  },
  [MUTATIONS.RESET_TICKETS](state: IState) {
    Vue.set(state, 'selectedTickets', []);
  },
};
