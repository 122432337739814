
import { Component, Vue } from 'vue-property-decorator';
import Loading from '@/components/General/Loading.vue';
import { MUTATIONS } from '@/constants/mutationNames';
import { ACTIONS } from '@/constants/actionNames';
import { GETTERS } from '@/constants/getterNames';
import { IBusinessNaw } from '@/interfaces/IBusinessNaw';
import { ERROR_MESSAGES } from '@/constants/errorMessages';
import { API_ERROR_CODES } from '@/constants/apiErrorCodes';
import { ADMIN_ROUTES, BUSINESS_ROUTES } from '@/constants/routeNames';
import AXIOS from '@/utils/apiUtil';
import { API_ROUTES } from '@/constants/apiRoutes';

@Component({
  components: {
    Loading,
  },
})
export default class LoginView extends Vue {
  public isLoading: boolean = false;
  public password: string = '';
  public isLoggedIn: boolean = false;
  public email: string = '';
  public inputs: IBusinessNaw = {
    fullName: '',
    businessName: '',
    address: '',
    accept: false,
    zipCode: '',
    city: '',
    newPassword: '',
    newPasswordRepeat: '',
    vatNumber: ''
  };

  get isFirstLogin() {
    return this.$store.getters[GETTERS.GET_IS_FIRST_LOGIN];
  }

  get userIsLoggedIn() {
    return this.$store.getters[GETTERS.GET_IS_LOGGED_IN];
  }

  public mounted() {
    if (this.userIsLoggedIn && !this.isFirstLogin) {
      this.$router.push({
        name: BUSINESS_ROUTES.ROUTE_TICKETS
      });
    }
  }

  public async doLogin() {
    this.isLoading = true;

    if (!this.email || !this.password) {
      this.$store.commit(MUTATIONS.SET_ERROR, {
        code: API_ERROR_CODES.REQUEST_BAD,
        message: ERROR_MESSAGES.LOGIN_DENIED
      });

      return;
    }

    this.$store.dispatch(ACTIONS.USER_LOGIN, {
      password: this.password,
      email: this.email
    }).then((
        res: { status: number, data: { ok: boolean, data: { isAdmin: boolean, token: string, firstLogin: boolean } } },
    ) => {
      if (res.status === API_ERROR_CODES.REQUEST_OK && res.data.ok) {
        this.$store.commit(MUTATIONS.IS_FIRST_LOGIN, res.data.data.firstLogin);
        this.$store.commit(MUTATIONS.SET_IS_LOGGED_IN, true);
        this.$store.commit(MUTATIONS.SET_IS_ADMIN, res.data.data.isAdmin);
        this.$store.commit(MUTATIONS.SET_AUTH_TOKEN, res.data.data.token);

        this.isLoggedIn = true;

        if (!res.data.data.firstLogin && !res.data.data.isAdmin) {
          this.$router.push({
            name: BUSINESS_ROUTES.ROUTE_TICKETS
          });
        } else if (res.data.data.isAdmin) {
          this.$router.push({
            name: ADMIN_ROUTES.ROUTE_ADMIN
          });
        }
      }
    }).catch(() => {
      this.$store.commit(MUTATIONS.SET_ERROR, {
        code: API_ERROR_CODES.REQUEST_BAD,
        message: ERROR_MESSAGES.LOGIN_DENIED
      });
    }).finally(() => {
      this.isLoading = false;
    });
  }

  public async updateNAW() {
    this.isLoading = true;

    const {fullName, businessName, address, accept, zipCode, city, newPassword, newPasswordRepeat} = this.inputs;

    if (
        !fullName ||
        !businessName ||
        !address ||
        !zipCode ||
        !city ||
        !newPassword ||
        !newPasswordRepeat ||
        !accept
    ) {
      this.$store.commit(MUTATIONS.SET_ERROR, {
        code: API_ERROR_CODES.REQUEST_BAD,
        message: ERROR_MESSAGES.LOGIN_DENIED
      });

      this.isLoading = false;

      return;
    }

    if (newPassword !== newPasswordRepeat) {
      this.$store.commit(MUTATIONS.SET_ERROR, {
        code: API_ERROR_CODES.REQUEST_BAD,
        message: ERROR_MESSAGES.REPEAT_PASSWORD
      });

      this.isLoading = false;

      return;
    }

    const authToken: string = this.$store.getters[GETTERS.GET_AUTH_TOKEN];

    this.$store.dispatch(ACTIONS.USER_SET_NAW, this.inputs).then((res) => {
      if (res.status === API_ERROR_CODES.REQUEST_OK && res.data.ok) {
        AXIOS.post(
            API_ROUTES.ROUTE_CHANGE_PASSWORD,
            {
              password: this.password,
            }, {
              headers: {
                Authentication: authToken,
              },
            },
        ).then((status) => {
          if (status.status === API_ERROR_CODES.REQUEST_OK && status.data.ok) {
            this.$store.commit(MUTATIONS.IS_FIRST_LOGIN, false);

            this.$router.push({
              name: BUSINESS_ROUTES.ROUTE_TICKETS
            });
          }
        }).catch(() => {
          this.$store.commit(MUTATIONS.SET_ERROR, {
            code: API_ERROR_CODES.REQUEST_BAD,
            message: ERROR_MESSAGES.UPDATING_DATA
          });
        }).finally(() => {
          this.isLoading = false;
        });
      }
    }).catch(() => {
      this.$store.commit(MUTATIONS.SET_ERROR, {
        code: API_ERROR_CODES.REQUEST_BAD,
        message: ERROR_MESSAGES.UPDATING_DATA
      });
    });
  }
}
