
import DelegateLoader from '@/mixins/DelegateLoader';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import Loading from '@/components/General/Loading.vue';
import validateEmail from '@/utils/validateUtil';
import { MUTATIONS } from '@/constants/mutationNames';
import { ACTIONS } from '@/constants/actionNames';
import IError from '@/interfaces/IError';
import { API_ERROR_CODES } from '@/constants/apiErrorCodes';
import { ERROR_MESSAGES } from '@/constants/errorMessages';
import { GETTERS } from '@/constants/getterNames';

interface SelectEvent extends Event {
  target: HTMLSelectElement;
}

interface Inputs {
  accept: boolean;
  marketing: boolean;
  acceptCorona: boolean;
  phone: string;
  email: string;
  lastName: string;
  firstName: string;
  prefix: string;
  emailRepeat: string;
  country: string;
  birthDay: string;
  questions: {
    group: string,
    disability: string,
    disability_people: number
  };
  answer: string;
}

@Component({
  components: {
    Loading,
  },
})
export default class NAWComponent extends Mixins(DelegateLoader) {

  @Prop(String) public readonly text!: string;

  public creatingOrder: boolean = false;

  public inputs: Inputs = {
    accept: true,
    acceptCorona: true,
    marketing: false,
    phone: '',
    email: '',
    lastName: '',
    firstName: '',
    prefix: '',
    emailRepeat: '',
    country: 'none',
    birthDay: '',
    questions: {
      group: '',
      disability: 'false',
      disability_people: 0
    },
    answer: 'none'
  };

  public errors: { input: string, message: string }[] = []

  public errorMessage(input: string) {
    return this.errors.find((error) => error.input === input);
  }

  get canProceed(): boolean {
    this.errors = [];

    if (this.inputs.firstName.length === 0) {
      this.errors.push({ input: 'firstName', message: 'errors.naw.firstName' })
    }

    if (this.inputs.lastName.length === 0) {
      this.errors.push({ input: 'lastName', message: 'errors.naw.lastName' })
    }

    if (this.inputs.email.length === 0 || !validateEmail(this.inputs.email, this.inputs.emailRepeat)) {
      this.errors.push({ input: 'email', message: 'errors.naw.email' })
    }

    if (!this.inputs.accept) {
      this.errors.push({ input: 'accept', message: 'errors.naw.accept' })
    }

    if (this.inputs.country === 'none') {
      this.errors.push({ input: 'country', message: 'errors.naw.country' })
    }

    if (this.inputs.answer === 'none') {
      this.errors.push({ input: 'answer', message: 'errors.naw.answer' })
    }

    if (this.errors.length > 0) return false;

    if (this.showExtraQuestions) {
      if (this.inputs.questions.group.length > 0) {
        if (this.inputs.questions.disability == 'true') {
          if (this.inputs.questions.disability_people > 0) {
            this.$store.commit(MUTATIONS.SET_NAW, this.inputs);

            return true;
          }
        } else {
          this.$store.commit(MUTATIONS.SET_NAW, this.inputs);

          return true;
        }
      }
    } else {
      this.$store.commit(MUTATIONS.SET_NAW, this.inputs);

      return true;
    }

    return false;
  }

  get showExtraQuestions(): boolean {
    return (this.$store.getters[GETTERS.GET_TOTAL_TICKET_AMOUNT]) >= 15;
  }

  public async mounted() {
    await this.delegate.beforeRouteEnterNaw();
  }

  public countryChanged(event: SelectEvent): void {
    this.inputs.country = event.target.value;
  }

  public answerChanged(event: SelectEvent): void {
    this.inputs.answer = event.target.value;
  }

  public nextStep(): void {
    if (!this.canProceed) {
      window.scrollTo(0,0)
      return;
    }

    this.creatingOrder = true;

    this.$store.dispatch(ACTIONS.CREATE_ORDER, this.$i18n.locale).then((res) => {
      this.creatingOrder = false;
      if (res.status === API_ERROR_CODES.REQUEST_OK) {
        this.$store.commit(MUTATIONS.SET_ORDER_ID, res.data.data.orderId);
        this.delegate.nextNaw();
      } else {
        this.$store.commit(MUTATIONS.SET_ERROR, {
          code: API_ERROR_CODES.REQUEST_BAD,
          message: ERROR_MESSAGES.CREATE_ORDER_FAULT,
        } as IError);
      }
    }).catch(() => {
      this.creatingOrder = false;
      this.$store.commit(MUTATIONS.SET_ERROR, {
        code: API_ERROR_CODES.REQUEST_BAD,
        message: ERROR_MESSAGES.CREATE_ORDER_FAULT,
      } as IError);
    });
  }
}
