import english from '@/languages/en';
import dutch from '@/languages/nl';
import french from '@/languages/fr';
import german from '@/languages/de';

const translation = {
  en: english,
  nl: dutch,
  fr: french,
  de: german
};

export default translation;
