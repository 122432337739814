import NumberFormat = Intl.NumberFormat;

const FORMAT_CURRENCY = (price: number): string => {
  const formatter: NumberFormat = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  });

  return formatter.format(price);
};

export default FORMAT_CURRENCY;
