
import { GETTERS } from '@/constants/getterNames';
import { MUTATIONS } from '@/constants/mutationNames';
import ITicket from '@/interfaces/ITicket';
import FORMAT_CURRENCY from '@/utils/currencyFormatterUtil';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Cart extends Vue {
  get tickets() {
    return [...this.$store.getters[GETTERS.GET_SELECTED_TICKETS], ...this.$store.getters[GETTERS.GET_SELECTED_ARTICLES]];
  }

  get time() {
    return this.$store.getters[GETTERS.GET_TIME_SLOT];
  }

  public formatCurrency = (price: number): string => FORMAT_CURRENCY(price);

  public addTicket(ticket: ITicket) {
    this.$store.commit(MUTATIONS.ADD_TICKET, ticket);
  }

  public deselectTicket(ticket: ITicket) {
    this.$store.commit(MUTATIONS.REMOVE_TICKET, ticket);
  }

  public removeTicket(ticket: ITicket) {
    this.$store.commit(MUTATIONS.REMOVE_TICKET_GROUP, ticket);
  }
}
