export enum GIFTCARD_ROUTES {
  ROUTE_TICKETS = 'giftcard/tickets',
  ROUTE_NAW = 'giftcard/naw',
  ROUTE_ARTICLES = 'giftcard/articles',
  ROUTE_PAYMENT = 'giftcard/payment',
}

export enum NOCTURNES_ROUTES {
  ROUTE_DATE = 'nocturnes/date',
  ROUTE_TICKETS = 'nocturnes/tickets',
  ROUTE_NAW = 'nocturnes/naw',
  ROUTE_ARTICLES = 'nocturnes/articles',
  ROUTE_PAYMENT = 'nocturnes/payment',
}

export enum TICKET_ROUTES {
  ROUTE_TICKETS = 'ticket/tickets',
  ROUTE_DATE = 'ticket/date',
  ROUTE_ARTICLES = 'ticket/articles',
  ROUTE_NAW = 'ticket/naw',
  ROUTE_PAYMENT = 'ticket/payment',
}

export enum BUSINESS_ROUTES {
  ROUTE_LOGIN = 'business/login',
  ROUTE_TICKETS = 'business/tickets',
  ROUTE_ARTICLES = 'business/articles',
  ROUTE_DATE = 'business/date',
  ROUTE_COMPLETE = 'business/complete',
}

export enum ADMIN_ROUTES {
  ROUTE_ADMIN = 'business/admin',
}

export enum WORKSHOP_ROUTES {
  ROUTE_TICKETS = 'workshop/tickets',
  ROUTE_DATE = 'workshop/date',
  ROUTE_ARTICLES = 'workshop/articles',
  ROUTE_NAW = 'workshop/naw',
  ROUTE_PAYMENT = 'workshop/payment',
}
