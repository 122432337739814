export const ERROR_MESSAGES = {
  NO_TIMES_AVAILABLE: 'errors.noTimesAvailable',
  CREATE_ORDER_FAULT: 'errors.createOrder',
  LOGIN_DENIED: 'errors.loginDenied',
  REPEAT_PASSWORD: 'errors.repeatPassword',
  UPDATING_DATA: 'errors.updateData',
  EMAIL_NOT_ADDED: 'errors.emailNotAdded',
  EMAIL_NOT_REMOVED: 'errors.emailNotRemoved',
  GETTING_USERS: 'errors.gettingUsers'
};
