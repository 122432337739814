
import { Component, Mixins } from 'vue-property-decorator';
import { GETTERS } from '@/constants/getterNames';
import Loading from '@/components/General/Loading.vue';
import FORMAT_CURRENCY from '../../utils/currencyFormatterUtil';
import DelegateLoader from '@/mixins/DelegateLoader';
import { MUTATIONS } from '@/constants/mutationNames';
import ITicket from '@/interfaces/ITicket';
import { BUSINESS_ROUTES } from '@/constants/routeNames';
import { ACTIONS } from '@/constants/actionNames';
import { API_ERROR_CODES } from '@/constants/apiErrorCodes';
import { ERROR_MESSAGES } from '@/constants/errorMessages';
import IError from '@/interfaces/IError';

@Component({
  components: {
    Loading,
  }
})
export default class CompleteComponent extends Mixins(DelegateLoader) {
  public isPaid: boolean = false;
  public isLoading: boolean = false;
  public accept: boolean = false;

  get totalPrice() {
    return this.$store.getters[GETTERS.GET_TOTAL_PRICE];
  }

  get tickets() {
    return this.$store.getters[GETTERS.GET_SELECTED_TICKETS];
  }

  get time() {
    return this.$store.getters[GETTERS.GET_TIME_SLOT];
  }

  public formatCurrency = (price: number): string => FORMAT_CURRENCY(price);

  public async mounted() {
    await this.delegate.beforeRouteEnterPayment();
  }

  public resetCurrentOrder() {
    this.$store.commit(MUTATIONS.SET_TIME, {});

    this.tickets.forEach((ticket: ITicket) => {
      this.$store.commit(MUTATIONS.REMOVE_TICKET, ticket);
    });

    this.tickets.forEach((ticket: ITicket) => {
      this.$store.commit(MUTATIONS.REMOVE_TICKET_GROUP, ticket);
    });

    this.$router.push({
      name: BUSINESS_ROUTES.ROUTE_TICKETS
    });
  }

  public createAndPayOrder() {
    this.isLoading = true;

    if (!this.accept) {
      this.$store.commit(MUTATIONS.SET_ERROR, {
        code: API_ERROR_CODES.REQUEST_BAD,
        message: ERROR_MESSAGES.CREATE_ORDER_FAULT,
      } as IError);
    }

    this.$store.dispatch(ACTIONS.USER_CREATE_ORDER, this.$i18n.locale).then((res) => {
      if (res.status === API_ERROR_CODES.REQUEST_OK) {
        this.isPaid = true;
      }
    }).catch(() => {
      this.$store.commit(MUTATIONS.SET_ERROR, {
        code: API_ERROR_CODES.REQUEST_BAD,
        message: ERROR_MESSAGES.CREATE_ORDER_FAULT,
      } as IError);
    }).finally(() => {
      this.isLoading = false;
    });
  }
}
